import { ofType } from "redux-observable";
import { map, switchMap } from "rxjs";
import {
  CACHE,
  DEMOGRAPHICS_LIST,
  LOGIN,
  LOGIN_INFO,
  GET_PATIENT_DETAILS,
  SAVE_PATIENT_DETAILS,
  INSURANCE_LIST,
  PATIENT_NOTES,
  SAVE_NOTES_BY_PATIENT_ID,
  SEARCH_PAYER,
  INSURANCE_SAVE,
  CLAIM_HISTORY,
  FILE_LIST,
  FILE_DOWNLOAD,
  FILE_UPLOAD,
  UPDATE_CLAIM_STATUS,
  FACILITY_LIST,
  PROVIDER_LIST,
  PAYOR_ADDRESS_LIST,
  POLICY_HOLDER_SAVE,
  CREATE_INSURANCE_ADDRESS,
  SEARCH_PATIENT,
} from "./demographics.types";
import {
  claimHistoryResponse,
  createInsuranceAddressResponse,
  demographicsListResponse,
  facilityListResponse,
  fileDownloadResponse,
  fileListResponse,
  fileUploadResponse,
  getCacheResponse,
  insuranceListResposne,
  insuranceSaveResponse,
  loginInfoResponse,
  loginResponse,
  patientDetailsResponse,
  patientNotesResponse,
  saveNotesByPatientIdRes,
  payorAddressListResponse,
  policyHolderSaveResponse,
  providerListResponse,
  savePatientDetailsResponse,
  searchPatientResponse,
  searchPayerResponse,
  updateClaimStatusResponse,
} from "./demographics.actions";
import { ajax as UtilsAjax } from "../../Utils";
import { ajaxUtilsForNots, objectToUrl } from "../../Utils/ajax-utils";
import environment from "../../environments/environment";
import { Apis } from "../../Redux/APIs";
import { ajax } from "rxjs/ajax";

const { ClinicBaseUrl } = environment;
const DemographicsEpic = {};
// https://staging.api.trillium.health
DemographicsEpic.login = (action$) =>
  action$.pipe(
    ofType(LOGIN),
    switchMap((action) =>
      ajax({
        headers: {
          "application-id": 7,
          "Content-Type": "application/json",
        },
        url: `${ClinicBaseUrl}/${Apis.eligibility_LoginApi}`,
        method: "POST",
        body: JSON.stringify(action.payload),
      }).pipe(
        map(
          (response) => loginResponse(response)
          // takeUntil(action$.pipe(ofType(REQUEST_CANCEL))),
        )
      )
    )
  );
  DemographicsEpic.demographicsList = (action$) => 
     action$.pipe(
      ofType(DEMOGRAPHICS_LIST),
      switchMap((action) => {
        return UtilsAjax({
          url: objectToUrl(
            `${ClinicBaseUrl}/${Apis.AccountsTable_getPatienTablelist}`,
            action.payload
          ),
          method: "GET",
          headers: {
            'Content-Type': 'application/x-www-form-urlencoded'}
        }).pipe(
          map((response) => demographicsListResponse(response))
        );
      })
    );
  
  
  // DemographicsEpic.searchPatientApi = (action$) =>{
  //  return  action$.pipe(
  //     ofType(SEARCH_PATIENT),
  //     switchMap((action) =>
  //       UtilsAjax({
  //         url: objectToUrl(
  //           `${ClinicBaseUrl}/trillium-clinic-service/v1/patient/search`,
  //           action.payload
  //         ),
  //         // url: `${ClinicBaseUrl}/trillium-clinic-service/v1/patient/search?lastName=${action.payload.searchLastName}&dob=${action.payload.searchDob}&clinicId=${action.payload.clinicId}  &dob=${action.payload.searchDob}  `,
  //         // url: `${ClinicBaseUrl}/trillium-clinic-service/v1/patient/search?firstName=${action.payload.firstName}&lastName=${action.payload.lastName}&searchDob=${action.payload.searchDob}&clinicId=${action.payload.clinicId}`,
  //         method: 'GET',
  //       }).pipe(
  //         map(
  //           (response) => searchPatientResponse(response)
  //           // takeUntil(action$.pipe(ofType(REQUEST_CANCEL))),
  //         )
  //       )
  //     )
  // };
 
   DemographicsEpic.searchPatientApi= (action$) =>
      action$.pipe(
        ofType(SEARCH_PATIENT),
        switchMap((action) => {
          return UtilsAjax({
            url: objectToUrl(
              `${ClinicBaseUrl}/trillium-clinic-service/v1/patient/search`,
              action.payload
            ),
            method: 'GET'
          }).pipe(
            map((response) => {
              return searchPatientResponse(response);
            })
          );
        })
      )
  
  

DemographicsEpic.loginInfo = (action$) =>
  action$.pipe(
    ofType(LOGIN_INFO),
    switchMap((action) =>
      UtilsAjax({
        url: `${ClinicBaseUrl}/${
          Apis.eligibility_Logininfo
        }?applicationId=${12}`,
        method: "GET",
      }).pipe(
        map(
          (response) => loginInfoResponse(response)
          // takeUntil(action$.pipe(ofType(REQUEST_CANCEL))),
        )
      )
    )
  );

DemographicsEpic.cacheApi = (action$) =>
  action$.pipe(
    ofType(CACHE),
    switchMap((action) =>
      UtilsAjax({
        url: `${ClinicBaseUrl}/trillium-internal-service/v1/common/list/${action.payload}?applicationId=4`,
        method: "GET",
      }).pipe(
        map(
          (response) => getCacheResponse(response)
          // takeUntil(action$.pipe(ofType(REQUEST_CANCEL))),
        )
      )
    )
  );

DemographicsEpic.getPatientDetails = (action$) =>
  action$.pipe(
    ofType(GET_PATIENT_DETAILS),
    switchMap((action) =>
      UtilsAjax({
        url: `${ClinicBaseUrl}/${Apis.getPatientById}/${action.payload}`,
        method: "GET",
        // body: action.payload,
      }).pipe(
        map(
          (response) => patientDetailsResponse(response)
          // takeUntil(action$.pipe(ofType(REQUEST_CANCEL))),
        )
      )
    )
  );

DemographicsEpic.savePatientDetails = (action$) =>
  action$.pipe(
    ofType(SAVE_PATIENT_DETAILS),
    switchMap((action) =>
      UtilsAjax({
        url: `${ClinicBaseUrl}/${Apis.getPatientById}/`,
        method: "POST",
        body: JSON.stringify(action.payload),
      }).pipe(
        map(
          (response) => savePatientDetailsResponse(response)
          // takeUntil(action$.pipe(ofType(REQUEST_CANCEL))),
        )
      )
    )
  );

DemographicsEpic.getPolicyByPatientId = (action$) =>
  action$.pipe(
    ofType(INSURANCE_LIST),
    switchMap((action) =>
      UtilsAjax({
        url: `${ClinicBaseUrl}/${Apis.getPolicyByPatientId}/${action.payload}`,
        method: "GET",
      }).pipe(
        map(
          (response) => insuranceListResposne(response)
          // takeUntil(action$.pipe(ofType(REQUEST_CANCEL))),
        )
      )
    )
  );

DemographicsEpic.PatientNotes = (action$) =>
  action$.pipe(
    ofType(PATIENT_NOTES),
    switchMap((action) =>
      UtilsAjax({
        url: `${ClinicBaseUrl}/${Apis.patienNotesApi}/${action.payload}`,
        method: "GET",
      }).pipe(
        map(
          (response) => patientNotesResponse(response)
          // takeUntil(action$.pipe(ofType(REQUEST_CANCEL))),
        )
      )
    )
  );
DemographicsEpic.savePatientNotes = (action$) =>
  action$.pipe(
    ofType(SAVE_NOTES_BY_PATIENT_ID),
    switchMap((action) =>
      ajaxUtilsForNots({
        url: `${ClinicBaseUrl}/${Apis.savePatientNotesApi}`,
        method: "POST",
        body: JSON.stringify(action.payload),
      }).pipe(
        map(
          (response) => saveNotesByPatientIdRes(response)
          // takeUntil(action$.pipe(ofType(REQUEST_CANCEL))),
        )
      )
    )
  );
DemographicsEpic.searchPayer = (action$) =>
  action$.pipe(
    ofType(SEARCH_PAYER),
    switchMap((action) =>
      UtilsAjax({
        url: `${ClinicBaseUrl}/${Apis.searchPayer}?name=${action.payload.payerName}&clinicId=${action.payload.clinicId}`,
        method: "GET",
        body: JSON.stringify(action.payload),
      }).pipe(
        map(
          (response) => searchPayerResponse(response)
          // takeUntil(action$.pipe(ofType(REQUEST_CANCEL))),
        )
      )
    )
  );

DemographicsEpic.insuranceSave = (action$) =>
  action$.pipe(
    ofType(INSURANCE_SAVE),
    switchMap((action) =>
      UtilsAjax({
        headers: {
          "application-id": 7,
        },
        url: `${ClinicBaseUrl}/${Apis.insuranceSaveApi}`,
        method: "POST",
        body: JSON.stringify(action.payload),
      }).pipe(
        map(
          (response) => insuranceSaveResponse(response)
          // takeUntil(action$.pipe(ofType(REQUEST_CANCEL))),
        )
      )
    )
  );

DemographicsEpic.claimHistory = (action$) =>
  action$.pipe(
    ofType(CLAIM_HISTORY),
    switchMap((action) =>
      UtilsAjax({
        url: `${ClinicBaseUrl}/${Apis.claimHistoryApi}`,
        method: "POST",
        body: JSON.stringify(action.payload),
      }).pipe(
        map(
          (response) => claimHistoryResponse(response)
          // takeUntil(action$.pipe(ofType(REQUEST_CANCEL))),
        )
      )
    )
  );

DemographicsEpic.fileList = (action$) =>
  action$.pipe(
    ofType(FILE_LIST),
    switchMap((action) =>
      UtilsAjax({
        headers: {
          "application-id": 6,
          "Content-Type": "application/json",
          // userId: `${action.payload.userId}`,
          // userName: `${action.payload.userName}`,
        },
        url: `${ClinicBaseUrl}/trillium-internal-service/v1/common/file/list?patientId=${action.payload.patientId}`,
        method: "GET",
        body: JSON.stringify(action.payload),
      }).pipe(
        map(
          (response) => fileListResponse(response)
          // takeUntil(action$.pipe(ofType(REQUEST_CANCEL))),
        )
      )
    )
  );

DemographicsEpic.fileDownload = (action$) =>
  action$.pipe(
    ofType(FILE_DOWNLOAD),
    switchMap((action) =>
      UtilsAjax({
        headers: {
          "application-id": 6,
          "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
        },
        // url: `${ClinicBaseUrl}/${Apis.fileDownloadApi}?fileId=${action.payload.fileId}&fileName=${action.payload.fileName}&image_type=${action.payload.image_type}`,
        url: `${ClinicBaseUrl}/trillium-internal-service/v1/common/file/download?fileId=${action.payload.fileId}&fileName=${action.payload.fileName}&isInternal=${action.payload.isInternal}`,
        method: "GET",
        responseType: "blob",
      }).pipe(
        map(
          (response) => fileDownloadResponse(response)
          // takeUntil(action$.pipe(ofType(REQUEST_CANCEL))),
        )
      )
    )
  );

DemographicsEpic.FileUpload = (action$) =>
  action$.pipe(
    ofType(FILE_UPLOAD),
    switchMap((action) =>
      ajax({
        headers: {
          "application-id": 6,
          enctype: "multipart/form-data",
          Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
        },
        // url: `${ClinicBaseUrl}/trillium-internal-service/v1/image/upload?patientId=${action.payload.patientId}`,
        url: `${ClinicBaseUrl}/trillium-internal-service/v1/common/file/upload/`,
        method: "POST",
        body: action.payload.data,
      }).pipe(
        map(
          (response) => fileUploadResponse(response)
          // takeUntil(action$.pipe(ofType(REQUEST_CANCEL))),
          // console.log(action.payload.data, "file data")
        )
      )
    )
  );

DemographicsEpic.updateClaimStatus = (action$) =>
  action$.pipe(
    ofType(UPDATE_CLAIM_STATUS),
    switchMap((action) =>
      UtilsAjax({
        // url: `${ClinicBaseUrl}/${Apis.getPatientById}/${action.payload}`,
        url: `${ClinicBaseUrl}/trillium-clinic-service/v1/details/claim/${action.payload.clinicId}?claimId=${action.payload.claimId}&claimStatus=${action.payload.claimStatus}`,
        method: "PUT",
        // body: action.payload,
      }).pipe(
        map(
          (response) => updateClaimStatusResponse(response)
          // takeUntil(action$.pipe(ofType(REQUEST_CANCEL))),
        )
      )
    )
  );

DemographicsEpic.facilityList = (action$) =>
  action$.pipe(
    ofType(FACILITY_LIST),
    switchMap((action) =>
      UtilsAjax({
        url: objectToUrl(
          `${ClinicBaseUrl}/trillium-clinic-service/v1/location/list/all`,
          action.payload
        ),
        method: "GET",
      }).pipe(
        map(
          (response) => facilityListResponse(response)
          // takeUntil(action$.pipe(ofType(REQUEST_CANCEL))),
        )
      )
    )
  );

DemographicsEpic.providerList = (action$) =>
  action$.pipe(
    ofType(PROVIDER_LIST),
    switchMap((action) =>
      UtilsAjax({
        url: objectToUrl(
          `${ClinicBaseUrl}/trillium-clinic-service/v1/provider/list/all`,
          action.payload
        ),
        method: "GET",
      }).pipe(
        map(
          (response) => providerListResponse(response)
          // takeUntil(action$.pipe(ofType(REQUEST_CANCEL))),
        )
      )
    )
  );

DemographicsEpic.payorAddressList = (action$) =>
  action$.pipe(
    ofType(PAYOR_ADDRESS_LIST),
    switchMap((action) =>
      UtilsAjax({
        url: `${ClinicBaseUrl}/trillium-clinic-service/v1/payerAddress/list?clinicId=${action.payload.clinicId}&payerId=${action.payload.payerId}`,
        method: "GET",
      }).pipe(
        map(
          (response) => payorAddressListResponse(response)
          // takeUntil(action$.pipe(ofType(REQUEST_CANCEL))),
        )
      )
    )
  );

DemographicsEpic.policyHolderSave = (action$) =>
  action$.pipe(
    ofType(POLICY_HOLDER_SAVE),
    switchMap((action) =>
      UtilsAjax({
        url: `${ClinicBaseUrl}/${Apis.policyHolderSave}/`,
        method: "POST",
        body: JSON.stringify(action.payload),
      }).pipe(
        map(
          (response) => policyHolderSaveResponse(response)
          // takeUntil(action$.pipe(ofType(REQUEST_CANCEL))),
        )
      )
    )
  );

DemographicsEpic.createInsuranceAddress = (action$) =>
  action$.pipe(
    ofType(CREATE_INSURANCE_ADDRESS),
    switchMap((action) =>
      UtilsAjax({
        url: `${ClinicBaseUrl}/${Apis.createInsuranceAddress}/`,
        method: "POST",
        body: JSON.stringify(action.payload),
      }).pipe(
        map(
          (response) => createInsuranceAddressResponse(response)
          // takeUntil(action$.pipe(ofType(REQUEST_CANCEL))),
        )
      )
    )
  );

export default DemographicsEpic;
