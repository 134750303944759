import React from "react";
import Header from "../../Components/Header";
import DataTable from "../../Components/DataTable";
import "./style.css";

function DataTableContainer(props) {
  return (
    <div>
      <Header dob={""} patientName={""} type={""} dos={""} />

      <div className="data_table_container">
        <DataTable
          setCurrentRow={props.setCurrentRow}
          setPatientList={props.setPatientList}
          visible={(e) => props.visible(e)}
        />
      </div>
    </div>
  );
}

export default DataTableContainer;
