import React, { useEffect, useState } from "react";
import {
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  Grid,
} from "@material-ui/core";
import Input from "../Input";
import Dropdown from "../Dropdown";
import states from "../../HelperFunctions/states.json";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import {
  createInsuranceAddress,
  payorAddressList,
} from "../../Redux/Demographics/demographics.actions";
import "./style.css";
import toast from "react-hot-toast";

function AddInsuranceAddress(props) {
  const rowData = JSON.parse(localStorage.getItem("RowData"));
  const defaultValues = {
    id: 0,
    clinicId: rowData?.clinicId,
    payerName: "",
    payerId: props.payerId,
    payerAddress_addressLine1: "",
    payerAddress_addressLine2: "",
    payerAddress_city: "",
    payerAddress_state: "",
    payerAddress_country: "US",
    payerAddress_zip: "",
    active: 0,
  };
  const [values, setValues] = useState(defaultValues);

  useEffect(() => {
    if (props.payorDetails) {
      setValues({
        ...values,
        clinicId: props.payorDetails.clinicId
          ? props.payorDetails.clinicId
          : rowData.clinicId,
        payerId: props.payorDetails.payerId,
        payerName: props.payorDetails.payerName,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.payorDetails]);

  const handleCancel = () => {
    props.handleClose();
    setValues({
      ...values,
      payerAddress_addressLine1: "",
      payerAddress_addressLine2: "",
      payerAddress_city: "",
      payerAddress_state: null,
      payerAddress_country: "US",
      payerAddress_zip: "",
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    props.createInsuranceAddress({
      active: values.active,
      addressLine1: values.payerAddress_addressLine1,
      adressLine2: values.payerAddress_addressLine2,
      city: values.payerAddress_city,
      clinicId: values.clinicId,
      country: values.payerAddress_country,
      id: 0,
      payerId: values.payerId,
      state: values.payerAddress_state,
      zip: values.payerAddress_zip,
    });
  };

  useEffect(() => {
    if (props.demographicsData.createInsuranceAddress) {
      let response = props.demographicsData.createInsuranceAddress.response;
      if (response.responseCode === 0) {
        toast.success("Payor Address added successfully");
        props.demographicsData.createInsuranceAddress = null;
        handleCancel();
        props.payorAddress({
          clinicId: rowData?.clinicId,
          payerId: values.payerId,
        });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.demographicsData.createInsuranceAddress]);

  return (
    <Dialog
      fullWidth={true}
      maxWidth={"sm"}
      open={props.open}
      onClose={handleCancel}
    >
      <DialogTitle>Create Payor Address</DialogTitle>
      <DialogContent>
        <form onSubmit={handleSubmit}>
          <Grid container spacing={2}>
            <Grid item xs={6}>
              <Input
                label="Payor Name"
                disabled
                type="text"
                name="payorName"
                value={values.payerName}
              />
            </Grid>
            <Grid item xs={6}></Grid>
            <Grid item xs={4}>
              <Input
                required
                label="Address Line 1"
                type="text"
                name="addressLine1"
                value={values.payerAddress_addressLine1}
                onChange={(e) =>
                  setValues({
                    ...values,
                    payerAddress_addressLine1: e.target.value,
                  })
                }
              />
            </Grid>
            <Grid item xs={4}>
              <Input
                label="Address Line 2"
                type="text"
                name="addressLine2"
                value={values.payerAddress_addressLine2}
                onChange={(e) =>
                  setValues({
                    ...values,
                    payerAddress_addressLine2: e.target.value,
                  })
                }
              />
            </Grid>
            <Grid item xs={4}>
              <Input
                label="Zip"
                type="text"
                name="zip"
                value={values.payerAddress_zip}
                onChange={(e) =>
                  setValues({
                    ...values,
                    payerAddress_zip: e.target.value,
                  })
                }
              />
            </Grid>{" "}
            <Grid item xs={4}>
              <Input
                label="City"
                type="text"
                name="city"
                value={values.payerAddress_city}
                onChange={(e) =>
                  setValues({
                    ...values,
                    payerAddress_city: e.target.value,
                  })
                }
              />
            </Grid>
            <Grid item xs={4}>
              <Dropdown
                required
                options={states}
                label="State"
                value={values.payerAddress_state}
                onChange={(e) =>
                  setValues({ ...values, payerAddress_state: e.target.value })
                }
              />
            </Grid>
            <Grid item xs={4}>
              <Dropdown
                required
                options={[
                  { name: "USA", value: "US" },
                  { name: "Mexico", value: "Mexico" },
                ]}
                label="Country"
                value={values.payerAddress_country}
                onChange={(e) =>
                  setValues({ ...values, payerAddress_country: e.target.value })
                }
              />
            </Grid>
            {/* Save and Cancel buttons */}
            <Grid item xs={12} container justify="flex-end">
              <div
                style={{ display: "flex", padding: "1rem 1rem 0", gap: "1rem" }}
              >
                <Button onClick={handleCancel} color="primary">
                  Cancel
                </Button>
                <Button variant="contained" color="primary" type="submit">
                  Save
                </Button>
              </div>
            </Grid>
          </Grid>
        </form>
      </DialogContent>
    </Dialog>
  );
}

const mapStateToProps = (state) => ({
  demographicsData: state.demographics,
});

const mapDispatchToProps = (dispatch) => ({
  createInsuranceAddress: (values) => dispatch(createInsuranceAddress(values)),
  payorAddress: (values) => dispatch(payorAddressList(values)),
});
AddInsuranceAddress.propTypes = {
  createInsuranceAddress: PropTypes.func,
  payorAddress: PropTypes.func,
};
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AddInsuranceAddress);
