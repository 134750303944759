import React, { useEffect, useState } from "react";
import {
  Button,
  Paper,
  TableCell,
  TableContainer,
  TableRow,
  TextField,
} from "@material-ui/core";
import {
  patientNotes,
  saveNotesByPatientId,
  saveNotesByPatientIdRes,
} from "../../Redux/Demographics/demographics.actions";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import NoDataFound from "../../assets/nodata-found.png";
import "./style.css";
import toast from "react-hot-toast";

function Notes(props) {
  const [patientNotes, setPatientNotes] = useState([]);
  const [newNote, setNewNote] = useState("");
  const [error, setError] = useState(null);
  const [isSaveClicked, setISaveClicked] = useState(false);
  const [isNoteValid, setIsNotValid] = useState(false);
  const getNotes = () => {
    if (props.rowData.id) {
      props.patientNotes(props.rowData.id);
    }
  };
  useEffect(() => {
    props.patientNotes(props.rowData.id);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.rowData.id]);

  //set new notes from user input
  const getInputNote = (e) => {
    if (e) {
      if (e.target.value) {
        setNewNote(e.target.value);
      } else {
        setNewNote("");
      }
    }
  };
  const handleSubmit = (e) => {
    e.preventDefault();
    if (newNote.trim() === "") {
      setIsNotValid(true);
    } else {
      if (isNoteValid === false && newNote.length >= 3) {
        setISaveClicked(true);
        const sendingValues = {
          appointmentId: 0,
          claimId: 0,
          createdDate: new Date(),
          id: 0,
          note: newNote,
          patientId: parseInt(props.rowData.id),
          userName: "",
          visitId: 0,
        };
        props.saveNotesByPatientId(sendingValues);
        setNewNote("");
      }
    }
  };
  useEffect(() => {
    if (
      props.demographicsData.patientNotes &&
      props.demographicsData.patientNotes.response
    ) {
      let response = props.demographicsData.patientNotes.response;
      if (response.responseCode === 0) {
        let data = response.data;
        setPatientNotes(data);
      } else {
        setPatientNotes([]);
      }
    }
  }, [props.demographicsData.patientNotes]);
  useEffect(() => {
    if (
      props.demographicsData &&
      props.demographicsData.savePatientNotesRes &&
      props.demographicsData.savePatientNotesRes.response
    ) {
      let response = props.demographicsData.savePatientNotesRes.response;
      if (response.responseCode === 0) {
        toast.success("Notes added successfully");
        getNotes();
        setISaveClicked(false);
        setNewNote("");
        props.saveNotesByPatientIdRes(null);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.demographicsData.savePatientNotesRes]);
  return (
    <div className="call_history_container">
      <div className="call_history_heading">Notes</div>
      {/* <form className="nots_form" onSubmit={handleSubmit}>
        <div className="notesTabForm_container">
          <div className="notesTab__input">
            <TextField
              type="text"
              label="Add note"
              value={newNote}
              onChange={getInputNote}
              variant="outlined"
              fullWidth
              size="small"
            />
            {error && <div className="notes_error">{error}</div>}
          </div>

          <div className="notesTab__btnContainer">
            <Button
              variant="outlined"
              color="primary"
              type="submit"
              onClick={() => setNewNote("")}
            >
              Cancel
            </Button>
            <Button
              variant="contained"
              color="primary"
              type="submit"
              onClick={handleSubmit}
              disabled={isSaveClicked}
            >
              Save
            </Button>
          </div>
        </div>
      </form> */}
      <>
        {patientNotes.length > 0 ? (
          <TableContainer component={Paper} elevation={0}>
            {patientNotes.map((x) => (
              <TableRow key={x.id}>
                <TableCell className="call_history_table_cell">
                  {x.note}
                </TableCell>
              </TableRow>
            ))}
          </TableContainer>
        ) : (
          <div>
            <img
              style={{ height: "200px", width: "100%", padding: "0 7rem" }}
              src={NoDataFound}
              alt=""
            />
          </div>
        )}
      </>
    </div>
  );
}

const mapStateToProps = (state) => ({
  demographicsData: state.demographics,
});

const mapDispatchToProps = (dispatch) => ({
  patientNotes: (values) => dispatch(patientNotes(values)),
  saveNotesByPatientId: (values) => dispatch(saveNotesByPatientId(values)),
  saveNotesByPatientIdRes: (values) =>
    dispatch(saveNotesByPatientIdRes(values)),
});
Notes.propTypes = {
  patientNotes: PropTypes.func,
  saveNotesByPatientId: PropTypes.func,
  saveNotesByPatientIdRes: PropTypes.func,
};

export default connect(mapStateToProps, mapDispatchToProps)(Notes);
