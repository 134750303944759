import React, { useState, useEffect } from "react";
import {
  Checkbox,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TableSortLabel,
} from "@material-ui/core";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import {
  demographicsList,
  savePatientDetails,
} from "../../Redux/Demographics/demographics.actions";
import {
  dateTommddyyy,
  dateToyyymmdd,
} from "../../HelperFunctions/TimeDateTimestamp";
import EditIcon from "@material-ui/icons/Edit";
import DataTableFilter from "../DataTableFilter";
import { Link } from "react-router-dom";
import Dropdown from "../Dropdown";
import DobPicker from "../dobPicker";
import toast from "react-hot-toast";
import NoDataFound from "../../assets/nodata-found.png";
import "./style.css";

function DataTable(props) {
  const [rows, setRows] = useState([]);
  const [records, setRecords] = useState(0);
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(50);
  const [owner, setOwner] = useState([]);
  const [orderBy, setOrderBy] = useState("");
  const [orderByParameter, setOrderByParameter] = useState("");

  const [headCells, setHeadCells] = useState([
    { id: "dos", label: "DOS", active: false, direction: "desc", item: "DOS" },
    {
      id: "patientName",
      label: "Patient Name",
      active: false,
      direction: "desc",
      item: "LASTNAME",
    },
    { id: "dob", label: "DOB", active: false, direction: "desc", item: "DOB" },
    {
      id: "clinicName",
      label: "Clinic Name",
      active: false,
      direction: "desc",
      item: "CLINICNAME",
    },
    {
      id: "provider",
      label: "Provider",
      active: false,
      direction: "desc",
      item: "PROVIDER",
    },
    {
      id: "payor",
      label: "Payor",
      active: false,
      direction: "desc",
      item: "PAYOR",
    },
    {
      id: "owner",
      label: "Owner",
      active: false,
      direction: "desc",
      item: "OWNERNAME",
    },
    {
      id: "dueDate",
      label: "Due Date",
      active: false,
      direction: "desc",
      item: "DUEDATE",
    },
    {
      id: "patientStatus",
      label: "Pt Status",
      active: false,
      direction: "desc",
      item: "PATIENTSTATUS",
    },
    {
      id: "insStatus",
      label: "Ins Status",
      active: false,
      direction: "desc",
      item: "INSSTATUS",
    },
  ]);

  useEffect(() => {
    const f = props.demographicsData.filterForm;
    let params = {
      clinicId: f.clinic ? f.clinic : "",
      providerId: f.provider ? f.provider : "",
      // insStatus: f.insStatus ? f.insStatus : "",
      // patStatus: f.ptStatus ? f.ptStatus : "",
      insStatus: f.insStatus !== null ? f.insStatus : "",
      patStatus: f.ptStatus !== null ? f.ptStatus : "",
      ownerId: f.owner ? f.owner : "",
      start: page,
      limit: rowsPerPage,
      orderByParameter: orderByParameter,
      orderBy: orderBy,
      dosStart: f.startDate
        ? dateToyyymmdd(f.startDate)
        : dateToyyymmdd(new Date()),
      dosEnd: f.endDate ? dateToyyymmdd(f.endDate) : dateToyyymmdd(new Date()),
    };
    props.demographicsList(params);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    page,
    rowsPerPage,
    props.demographicsData.filterForm,
    orderBy,
    orderByParameter,
  ]);

  useEffect(() => {
    const cache = JSON.parse(localStorage.getItem("cache"));
    let owner = [];
    cache.userDtoList.forEach((x) => {
      owner.push({
        name: x.fullName,
        value: x.userId,
      });
    });
    setOwner(owner);
  }, []);

  useEffect(() => {
    if (props.demographicsData.tableData) {
      let response = props.demographicsData.tableData.response;
      if (response?.responseCode === 0) {
        setRows(response.data.results);
        localStorage.setItem("allItems", JSON.stringify(response.data.results));
        props.setPatientList(response.data.results);
        sessionStorage.setItem(
          "demographicsData",
          JSON.stringify(response.data.results)
        );
        setRecords(response.data.totalRecords);
        props.demographicsData.tableData = null;
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.demographicsData.tableData]);

  const sentenceCase = (txt) => {
    if (!txt) return "";
    return txt
      .toLowerCase()
      .split(" ")
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
      .join(" ");
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value));
    setPage(0);
  };

  const editClick = (data) => {
    localStorage.setItem("RowData", JSON.stringify(data));
    props.setCurrentRow(data);
    if (data.ownerId > 0) {
      props.demographicsList({
        start: 0,
        limit: 10,
        ownerId: data.ownerId,
      });
    }
  };

  const handleUpdate = (e, x, i, type) => {
    if (type === "owner") {
      let r = rows;
      r[i].ownerId = e.target.value;
      setRows([...r]);
      props.savePatientDetails({
        id: x.id,
        clinicId: x.clinicId,
        updateType: 1,
        ownerId: e.target.value,
        ownerDueDate: x.ownerDueDate ? x.ownerDueDate : null,
      });
    } else {
      let r = rows;
      r[i].ownerDueDate = e.target.value;
      setRows([...r]);
      props.savePatientDetails({
        id: x.id,
        clinicId: x.clinicId,
        updateType: 1,
        ownerId: x.ownerId ? x.ownerId : null,
        ownerDueDate: dateToyyymmdd(e.target.value),
      });
    }
  };

  useEffect(() => {
    if (props.demographicsData.savePatientDetails) {
      let response = props.demographicsData.savePatientDetails.response;
      if (response.responseCode === 0) {
        toast.success("Update Successfully");
        props.demographicsData.savePatientDetails = null;
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.demographicsData.savePatientDetails]);
  useEffect(() => {
    if (props.demographicsData.searchFilter) {
      let response = props.demographicsData.searchFilter;
      props.demographicsList(response);
      props.demographicsData.searchFilter = null;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.demographicsData?.searchFilter]);
  const handleSort = (index) => {
    let cell = headCells;
    cell.forEach((x, i) => {
      if (i === index) {
        cell[i].active = true;
        cell[i].direction = cell[i].direction === "asc" ? "desc" : "asc";
        setOrderByParameter(cell[i].item);
        setOrderBy(cell[i].direction === "asc" ? "desc" : "asc");
      } else {
        cell[i].active = false;
        cell[i].direction = "DESC";
      }
    });
    setHeadCells([...cell]);
  };

  return (
    <div>
      <div className="data_table_filter_container">
        <div>
          <TablePagination
            className="demographics_table_pagination"
            rowsPerPageOptions={[30, 40, 50, 60, 100]}
            component="div"
            count={records}
            page={page}
            rowsPerPage={rowsPerPage}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </div>
        <div>
          <DataTableFilter />
        </div>
      </div>
      <TableContainer>
        <Table>
          <TableHead className="dataTable_head">
            <TableRow>
              <TableCell className="data_table_cell_checkbox">
                <Checkbox size="small" style={{ color: "#808080" }} />
              </TableCell>
              {headCells &&
                headCells.map((x, i) => (
                  <TableCell
                    key={i}
                    className="data_table_cell"
                    sortDirection={false}
                  >
                    <TableSortLabel
                      onClick={(e) => handleSort(i)}
                      active={x.active}
                      direction={x.direction}
                    >
                      {x.label}
                    </TableSortLabel>
                  </TableCell>
                ))}
              <TableCell className="data_table_cell">Action</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {rows.map((x, i) => (
              <TableRow key={i}>
                <TableCell className="data_table_cell_checkbox">
                  <Checkbox size="small" style={{ color: "#808080" }} />
                </TableCell>
                <TableCell className="data_table_cell">{x.dos}</TableCell>
                <TableCell className="data_table_cell">
                  {sentenceCase(x.lastName) + ", " + sentenceCase(x.firstName)}
                </TableCell>
                <TableCell className="data_table_cell">
                  {" "}
                  {dateTommddyyy(x.dob)}{" "}
                </TableCell>
                <TableCell className="data_table_cell">
                  {sentenceCase(x.clinicName.substring(0, 23))}
                </TableCell>
                <TableCell className="data_table_cell">
                  {x.providerName}
                </TableCell>
                <TableCell className="data_table_cell">
                  {x.payorName
                    ? sentenceCase(x.payorName.substring(0, 15))
                    : ""}
                </TableCell>
                <TableCell
                  className="data_table_cell"
                  style={{ width: "165px" }}
                >
                  <Dropdown
                    className="owner_dropdown"
                    options={owner}
                    value={x.ownerId}
                    onChange={(e) => handleUpdate(e, x, i, "owner")}
                  />
                </TableCell>
                <TableCell className="data_table_cell dueDate">
                  <DobPicker
                    inputVariant={"standard"}
                    value={x.ownerDueDate}
                    onChange={(e) => handleUpdate(e, x, i, "dueDate")}
                  />
                </TableCell>
                <TableCell className="data_table_cell">
                  {x.active === 0
                    ? "Inactive"
                    : x.active === 1
                    ? "Active"
                    : x.active === 3
                    ? "Pending"
                    : ""}
                </TableCell>
                <TableCell className="data_table_cell">
                  {x.insuranceStatus === 0
                    ? "Inactive"
                    : x.insuranceStatus === 1
                    ? "Active"
                    : x.insuranceStatus === 3
                    ? "Pending"
                    : ""}
                </TableCell>
                <TableCell className="data_table_cell">
                  <div className="table_icon">
                    <Link to={`/edit/${x.id}`}>
                      <EditIcon
                        className="table_editBtn"
                        fontSize="small"
                        style={{ color: "#828282", cursor: "pointer" }}
                        onClick={() => editClick(x)}
                      />
                    </Link>
                  </div>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
        {rows.length === 0 && (
          <div className="nodatafound">
            <img src={NoDataFound} alt={"NoDataFound"} />
          </div>
        )}
      </TableContainer>
    </div>
  );
}

const mapStateToProps = (state) => ({
  demographicsData: state.demographics,
});

const mapDispatchToProps = (dispatch) => ({
  demographicsList: (values) => dispatch(demographicsList(values)),
  savePatientDetails: (values) => dispatch(savePatientDetails(values)),
});

DataTable.propTypes = {
  demographicsList: PropTypes.func,
  savePatientDetails: PropTypes.func,
};

export default connect(mapStateToProps, mapDispatchToProps)(DataTable);
