import React from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { Button } from "@material-ui/core";
import Input from "../../Components/Input";
import trilliumLogo from "../../assets/Trillium-health-logo.png";
import { useState, useEffect } from "react";
import "./style.css";
import {
  login,
  loginInfo,
} from "../../Redux/Demographics/demographics.actions";

function Login(props) {
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState(false);
  const handleLogin = (e) => {
    e.preventDefault();
    props.login({
      clinicId: 0,
      userName: username,
      password: password,
    });
  };

  useEffect(() => {
    localStorage.removeItem("currentCoder");
    localStorage.removeItem("currentEligibility");
  }, []);

  useEffect(() => {
    if (props.demographicsData.loginResponse) {
      let Response = props.demographicsData.loginResponse.response;
      if (Response.responseCode === 0) {
        let token = Response.data.accessToken;
        localStorage.setItem("accessToken", token);
        const userData = {
          userId: Response.data.userId,
          fullName: Response.data.fullName,
        };
        localStorage.setItem("userData", JSON.stringify(userData));
        props.loginInfo();
      } else {
        setError(true);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.demographicsData.loginResponse]);

  useEffect(() => {
    if (props.demographicsData.loginInfo) {
      let Response = props.demographicsData.loginInfo.response;
      if (Response.responseCode === 0) {
        localStorage.setItem("cache", JSON.stringify(Response.data));
        window.location.href = "/home";
      }
    }
  }, [props.demographicsData.loginInfo]);

  return (
    <div className="login__page scrollbarY--custom">
      <header className="login__header">
        <div className="login__header__bar">
          <a href="#0">
            <img
              src={trilliumLogo}
              alt="trillium-health-logo"
              className="login__header__logo"
            />
          </a>
        </div>
      </header>

      <main className="login__main">
        <section className="login__section">
          <div>
            <h1 className="login__grid__subheading">Demographics</h1>
          </div>

          <div>
            <form onSubmit={handleLogin} className="login__form">
              <div className="login__inputField">
                <Input
                  required
                  margin="normal"
                  type="text"
                  label="Username"
                  variant="outlined"
                  className="login_input"
                  onChange={(e) => setUsername(e.target.value)}
                  value={username}
                  error={error}
                />

                <Input
                  margin="normal"
                  required
                  type="password"
                  label="Password"
                  variant="outlined"
                  className="login_input"
                  onChange={(e) => setPassword(e.target.value)}
                  value={password}
                  error={error}
                  helperText={error && "Invalid Credentails"}
                />

                <div className="btnWrapper">
                  <Button
                    type="submit"
                    variant="contained"
                    color="primary"
                    className="login__submitBtn"
                  >
                    LOGIN
                  </Button>
                </div>

                <div className="helper_text_wrap">
                  <div className="helper_text_large">Forgot your password?</div>
                  <div className="helper_text_small">
                    Contact account manager to reset password
                  </div>
                </div>
              </div>
            </form>
          </div>
        </section>
      </main>
    </div>
  );
}

const mapStateToProps = (state) => ({
  demographicsData: state.demographics,
});

const mapDispatchToProps = (dispatch) => ({
  login: (values) => dispatch(login(values)),
  loginInfo: (values) => dispatch(loginInfo(values)),
});
Login.propTypes = {
  login: PropTypes.func,
  loginInfo: PropTypes.func,
};
export default connect(mapStateToProps, mapDispatchToProps)(Login);
