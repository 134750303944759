import React, { useEffect, useState } from "react";
import { Autocomplete } from "@material-ui/lab";
import { Button, Dialog, Grid, TextField } from "@material-ui/core";
import Dropdown from "../Dropdown";
import Input from "../Input";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import {
  insuranceList,
  insuranceSave,
  payorAddressList,
  searchPayer,
} from "../../Redux/Demographics/demographics.actions";
import toast from "react-hot-toast";
import CloseIcon from "@material-ui/icons/Close";
import AddCircleIcon from "@material-ui/icons/AddCircle";
import PolicyHolder from "../PolicyHolder";
import AddInsuranceAddress from "../AddInsuranceAddress";
import "./style.css";

function Insurance(props) {
  // const rowData = JSON.parse(localStorage.getItem("RowData"));
  const [values, setValues] = useState({
    active: 1,
    activeCoverageCleared: true,
    clarificationPresent: true,
    clinicId: 0,
    groupNo: "",
    id: 0,
    note: "",
    patientId: 0,
    payerAddressId: 0,
    payerId: 0,
    payerName: "",
    plan: "",
    policyHolderId: "",
    policyHolderName: "",
    policyHolderRelation: 0,
    policyNo: "",
    policyType: 9,
    ranking: "",
  });

  const [policyHolderList, setPolicyHolderList] = useState([]);
  const [payorList, setPayorList] = useState([]);
  const [payerAddress, setPayerAddress] = useState([
    {
      name: "No address found.",
    },
  ]);
  const [policyHolder, setPolicyHolder] = useState(false);
  const [insuranceAddress, setInsuranceAddress] = useState(false);

  const type = [
    { name: "Insurance", value: 9 },
    { name: "PIP", value: 7 },
    { name: "WC", value: 6 },
    { name: "Other ", value: 5 },
    { name: "HMO ", value: 3 },
  ];

  const ranking = [
    { name: "Primary", value: 1 },
    { name: "Secondary", value: 2 },
    { name: "Tertiary", value: 3 },
  ];

  useEffect(() => {
    if (
      props.demographicsData.insuranceList &&
      props.demographicsData.insuranceList.response
    ) {
      let response = props.demographicsData.insuranceList.response;
      if (response.responseCode === 0) {
        let data = response.data.policyHolderDtoList;
        let holders = [];
        // console.log(data,"policy holder list::::::::::::::::::");
        data.forEach((x) => {
          holders.push({
            name: x.firstName + " " + x.lastName,
            value: x.id,
            relation: x.relation,
          });
        });
        setPolicyHolderList([...holders]);
        // setPolicyHolderList(holders);
      }
    }
  }, [props.demographicsData.insuranceList]);

  const handleSearchPayer = (e) => {
    const rowData = JSON.parse(localStorage.getItem("RowData"));
    props.searchPayer({
      payerName: e.target.value,
      clinicId: rowData.clinicId,
    });
    setValues({ ...values, payerName: e.target.value });
  };

  useEffect(() => {
    if (props.demographicsData.searchPayer) {
      let response = props.demographicsData.searchPayer.response;
      if (response.responseCode === 0) {
        let data = response.data;
        setPayorList(data);
      }
    }
  }, [props.demographicsData.searchPayer]);

  const handleInsuranceSelection = (value) => {
    if (value) {
      setValues({ ...values, payerId: value.id, payerName: value.payerName });
    } else {
      setValues({ ...values, payerId: 0, payerName: "" });
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    // let id = window.location.href.split("/").pop();
    const rowData = JSON.parse(localStorage.getItem("RowData"));
    props.insuranceSave({
      ...values,
      patientId: props.rowData.id,
      clinicId: rowData.clinicId,
    });
  };

  useEffect(() => {
    if (props.demographicsData.insuranceSave) {
      let response = props.demographicsData.insuranceSave.response;
      if (response.responseCode === 0) {
        if (props.insuranceStatus && props.insuranceStatus > 0) {
          toast.success("Insurance update successfully");
        } else {
          toast.success("Insurance added successfully");
        }
        props.demographicsData.insuranceSave = null;
        props.setInsuranceStatus(null);
        // let id = window.location.href.split("/").pop();
        // console.log(id,"id ");
        props.insuranceList(
          JSON.parse(localStorage.getItem("patientInfo"))?.id
        );
        setValues({
          active: 1,
          activeCoverageCleared: true,
          clarificationPresent: true,
          clinicId: 0,
          groupNo: "",
          id: 0,
          note: "",
          patientId: 0,
          payerAddressId: 0,
          payerId: 0,
          payerName: "",
          plan: "",
          policyHolderId: "",
          policyHolderName: "",
          policyHolderRelation: 0,
          policyNo: "",
          policyType: 9,
          ranking: "",
        });
        props.handleClose();
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.demographicsData.insuranceSave]);

  useEffect(() => {
    if (props.insurance) {
      setValues({
        active: props.insurance.active,
        activeCoverageCleared: true,
        clarificationPresent: true,
        clinicId: props.insurance.clinicId,
        groupNo: props.insurance.groupNo,
        id: props.insurance.id,
        note: "",
        patientId: props.insurance.patientId,
        payerAddressId: props.insurance.payerAddressId,
        payerId: props.insurance.payerId,
        payerName: props.insurance.payerName,
        plan: "",
        policyHolderId: props.insurance.policyHolderId,
        policyHolderName: props.insurance.policyHolderName,
        policyHolderRelation: 7,
        policyNo: props.insurance.policyNo,
        policyType: props.insurance.policyType ? props.insurance.policyType : 9,
        ranking: props.insurance.ranking,
      });
      props.setInsuranceStatus(props.insurance.id);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.insurance]);

  const handleCloseDialog = () => {
    props.handleClose();
    props.setInsuranceStatus(null);
    setValues({
      active: 1,
      activeCoverageCleared: true,
      clarificationPresent: true,
      clinicId: 0,
      groupNo: "",
      id: 0,
      note: "",
      patientId: 0,
      payerAddressId: 0,
      payerId: 0,
      payerName: "",
      plan: "",
      policyHolderId: "",
      policyHolderName: "",
      policyHolderRelation: 0,
      policyNo: "",
      policyType: 9,
      ranking: "",
    });
  };

  useEffect(() => {
    const rowData = JSON.parse(localStorage.getItem("RowData"));
    if (values.payerId) {
      props.payorAddress({
        clinicId: rowData.clinicId,
        payerId: values.payerId,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [values.payerId]);

  useEffect(() => {
    if (props.demographicsData.payorAddressList) {
      let data = props.demographicsData.payorAddressList.response.data;
      if (data) {
        let payerAddList = data.map((x) => {
          return {
            name:
              x.addressLine1 +
              ", " +
              x.zip +
              ", " +
              x.city +
              ", " +
              x.state +
              ", " +
              x.country,
            value: x.id,
          };
        });
        setPayerAddress(payerAddList);
      } else {
        setPayerAddress([
          {
            name: "No address found.",
          },
        ]);
      }
    }
  }, [props.demographicsData.payorAddressList]);

  const handlePolicyHolder = () => {
    setPolicyHolder(true);
  };

  const handleClosePolicyHolder = () => {
    setPolicyHolder(false);
  };

  const handleInsuranceAddress = () => {
    setInsuranceAddress(true);
  };

  const handleCloseInsuranceAddress = () => {
    setInsuranceAddress(false);
  };

  return (
    <>
      <PolicyHolder open={policyHolder} handleClose={handleClosePolicyHolder} />
      <AddInsuranceAddress
        open={insuranceAddress}
        handleClose={handleCloseInsuranceAddress}
        payorDetails={values}
      />
      <Dialog
        fullWidth={true}
        maxWidth={"md"}
        open={props.open}
        onClose={handleCloseDialog}
      >
        <div className="insurance_tab_container">
          <form className="insurace_tab_form" onSubmit={handleSubmit}>
            <div className="form_heading">
              Insurance
              <div className="form_buttons">
                <Button
                  style={{ borderRadius: "10px" }}
                  variant="outlined"
                  color="primary"
                  type="reset"
                  onClick={() => handleCloseDialog()}
                >
                  Cancel
                </Button>
                <Button
                  style={{ borderRadius: "10px" }}
                  variant="contained"
                  color="primary"
                  type="submit"
                >
                  Save
                </Button>
              </div>
            </div>
            <div style={{ padding: "0 0.5rem" }}>
              <Grid container spacing={2}>
                <Grid container spacing={2}>
                  <Grid item xs={3}>
                    <Dropdown
                      required
                      variant="outlined"
                      options={ranking}
                      label="Ranking"
                      onChange={(e) =>
                        setValues({
                          ...values,
                          ranking: e.target.value,
                        })
                      }
                      value={values.ranking}
                    />
                  </Grid>
                  <Grid item xs={3}>
                    <div style={{ display: "flex", width: "109%" }}>
                      <Autocomplete
                        fullWidth
                        options={payorList}
                        getOptionLabel={(option) => option.payerName}
                        onChange={(e, v) => handleInsuranceSelection(v)}
                        value={{ payerName: values.payerName }}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            required
                            variant="outlined"
                            label="Insurance"
                            placeholder="Search insurance"
                            onChange={handleSearchPayer}
                          />
                        )}
                      />
                      <CloseIcon
                        onClick={() =>
                          setValues({
                            ...values,
                            payerName: "",
                            payerId: 0,
                          })
                        }
                        color="primary"
                        fontSize="small"
                        className={"insurance_closeIcon"}
                      />
                    </div>
                  </Grid>
                  <Grid item xs={3}>
                    <Input
                      required
                      type="text"
                      label="Policy#"
                      variant="outlined"
                      onChange={(e) =>
                        setValues({
                          ...values,
                          policyNo: e.target.value,
                        })
                      }
                      value={values.policyNo}
                    />
                  </Grid>
                  <Grid item xs={3}>
                    {" "}
                    <Input
                      type="text"
                      label="Group#"
                      variant="outlined"
                      onChange={(e) =>
                        setValues({
                          ...values,
                          groupNo: e.target.value,
                        })
                      }
                      value={values.groupNo}
                    />
                  </Grid>
                  <Grid item xs={3}>
                    {" "}
                    <div style={{ display: "flex", alignItems: "center" }}>
                      <Dropdown
                        required
                        options={policyHolderList}
                        variant="outlined"
                        label="Policyholder"
                        onChange={(e) =>
                          setValues({
                            ...values,
                            policyHolderId: e.target.value,
                            policyHolderRelation: policyHolderList.find(
                              (holder) => holder.value === e.target.value
                            ).relation,
                            policyHolderName: policyHolderList.find(
                              (holder) => holder.value === e.target.value
                            ).name,
                          })
                        }
                        value={values.policyHolderId}
                      />
                      <div style={{ cursor: "pointer" }}>
                        <AddCircleIcon
                          color="primary"
                          fontSize="large"
                          onClick={handlePolicyHolder}
                        />
                      </div>
                    </div>
                  </Grid>
                  <Grid item xs={3}>
                    <Dropdown
                      options={type}
                      variant="outlined"
                      label="Type"
                      onChange={(e) =>
                        setValues({
                          ...values,
                          policyType: e.target.value,
                        })
                      }
                      value={values.policyType}
                    />
                  </Grid>
                  <Grid item xs={3}>
                    <div style={{ display: "flex", alignItems: "center" }}>
                      <Dropdown
                        options={payerAddress}
                        variant="outlined"
                        label="Payor Address"
                        onChange={(e) =>
                          setValues({
                            ...values,
                            payerAddressId: e.target.value,
                          })
                        }
                        value={
                          values.payerAddressId ? values.payerAddressId : ""
                        }
                      />{" "}
                      {values.payerId ? (
                        <div style={{ cursor: "pointer" }}>
                          <AddCircleIcon
                            color="primary"
                            fontSize="large"
                            onClick={handleInsuranceAddress}
                          />
                        </div>
                      ) : (
                        ""
                      )}
                    </div>
                  </Grid>
                </Grid>
              </Grid>
            </div>
          </form>
        </div>
      </Dialog>
    </>
  );
}

const mapStateToProps = (state) => ({
  demographicsData: state.demographics,
});

const mapDispatchToProps = (dispatch) => ({
  searchPayer: (values) => dispatch(searchPayer(values)),
  insuranceSave: (values) => dispatch(insuranceSave(values)),
  insuranceList: (values) => dispatch(insuranceList(values)),
  payorAddress: (values) => dispatch(payorAddressList(values)),
});
Insurance.propTypes = {
  insuranceList: PropTypes.func,
  searchPayer: PropTypes.func,
  insuranceSave: PropTypes.func,
  payorAddress: PropTypes.func,
};
export default connect(mapStateToProps, mapDispatchToProps)(Insurance);
