export const Apis = {
  eligibility_LoginApi:
    "trillium-clinic-service/v1/authentication/clinic/user/login",
  AccountsTable_getPatienTablelist:
    "trillium-common-service/v1/common/patient/list/range",
  eligibility_Logininfo: "trillium-internal-service/v1/common/priorAuth/cache",
  getPatientById: "trillium-clinic-service/v1/patient",
  getPolicyByPatientId: "trillium-clinic-service/v1/account/policy/list",
  patienNotesApi: "trillium-clinic-service/v1/note/list/patient",
  savePatientNotesApi: "trillium-clinic-service/v1/note/patient",
  searchPayer: "trillium-clinic-service/v1/payer/search/name",
  insuranceSaveApi: "trillium-internal-service/v1/common/policy/",
  claimHistoryApi:
    "trillium-clinic-service/v1/details/getNewProviderProductivityReport/0?offset=0",
  fileListApi: "trillium-internal-service/v1/image/list/all",
  fileDownloadApi: "trillium-internal-service/v1/image/file/download",
  policyHolderSave: "trillium-clinic-service/v1/policyHolder",
  createInsuranceAddress: "trillium-clinic-service/v1/payerAddress",
};
