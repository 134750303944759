import React from "react";
import {
  BrowserRouter as Router,
  Route,
  Navigate,
  Routes,
} from "react-router-dom";
import { Toaster } from "react-hot-toast";
import "./GlobalStyles.css";

import Login from "./Containers/LoginContainer";
import EditContainer from "./Containers/EditContainer";
import DataTableContainer from "./Containers/DataTableContainer";
import useLocalStorage from "./Utils/useLocalStorage";

function App() {
  const [patients, setPatients] = useLocalStorage("patients", []);
  const [currentRow, setCurrentRow] = useLocalStorage("currentRow", []);
  const token = localStorage.getItem("accessToken");
  return (
    <div>
      <Toaster position="top-right" reverseOrder={false} />
      <Router>
        <Routes>
          <Route path="/login" element={<Login />} />
          <Route
            path="/home"
            element={
              token ? (
                <DataTableContainer
                  setCurrentRow={setCurrentRow}
                  setPatientList={setPatients}
                />
              ) : (
                <Login />
              )
            }
          />
          <Route
            path="/edit/:id"
            element={
              token ? (
                <EditContainer
                  setCurrentRow={setCurrentRow}
                  currentRow={currentRow}
                  patientList={patients}
                />
              ) : (
                <Login />
              )
            }
          />
          <Route path="*" element={<Navigate to="/login" />} />
        </Routes>
      </Router>
    </div>
  );
}

export default App;
