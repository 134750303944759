export const LOGIN = "LOGIN";
export const LOGIN_RES = "LOGIN_RES";
export const DEMOGRAPHICS_LIST = "DEMOGRAPHICS_LIST";
export const DEMOGRAPHICS_LIST_RES = "DEMOGRAPHICS_LIST_RES";
export const LOGIN_INFO = "LOGIN_INFO";
export const LOGIN_INFO_RES = "LOGIN_INFO_RES";
export const SET_FILTER = "SET_FILTER";
export const CACHE = "CACHE";
export const CACHE_RES = "CACHE_RES";
export const GET_PATIENT_DETAILS = "GET_PATIENT_DETAILS";
export const GET_PATIENT_DETAILS_RES = "GET_PATIENT_DETAILS_RES";
export const SAVE_PATIENT_DETAILS = "SAVE_PATIENT_DETAILS";
export const SAVE_PATIENT_DETAILS_RES = "SAVE_PATIENT_DETAILS_RES";
export const INSURANCE_LIST = "INSURANCE_LIST";
export const INSURANCE_LIST_RES = "INSURANCE_LIST_RES";
export const PATIENT_NOTES = "PATIENT_NOTES";
export const PATIENT_NOTES_RES = "PATIENT_NOTES_RES";
export const SAVE_NOTES_BY_PATIENT_ID ="SAVE_NOTES_BY_PATIENT_ID";
export const SAVE_NOTES_PATIENT_ID_RES ="SAVE_NOTES_BY_PATIENT_ID_RES";
export const SEARCH_PAYER = "SEARCH_PAYER";
export const SEARCH_PAYER_RES = "SEARCH_PAYER_RES";
export const INSURANCE_SAVE = "INSURANCE_SAVE";
export const INSURANCE_SAVE_RES = "INSURANCE_SAVE_RES";
export const CLAIM_HISTORY = "CLAIM_HISTORY";
export const CLAIM_HISTORY_RES = "CLAIM_HISTORY_RES";
export const FILE_LIST = "FILE_LIST";
export const FILE_LIST_RES = "FILE_LIST_RES";
export const FILE_DOWNLOAD = "FILE_DOWNLOAD";
export const FILE_DOWNLOAD_RES = "FILE_DOWNLOAD_RES";
export const FILE_UPLOAD = "FILE_UPLOAD";
export const FILE_UPLOAD_RES = "FILE_UPLOAD_RES";
export const UPDATE_CLAIM_STATUS = "UPDATE_CLAIM_STATUS";
export const UPDATE_CLAIM_STATUS_RES = "UPDATE_CLAIM_STATUS_RES";
export const FACILITY_LIST = "FACILITY_LIST";
export const FACILITY_LIST_RES = "FACILITY_LIST_RES";
export const PROVIDER_LIST = "PROVIDER_LIST";
export const PROVIDER_LIST_RES = "PROVIDER_LIST_RES";
export const PAYOR_ADDRESS_LIST = "PAYOR_ADDRESS_LIST";
export const PAYOR_ADDRESS_LIST_RES = "PAYOR_ADDRESS_LIST_RES";
export const POLICY_HOLDER_SAVE = "POLICY_HOLDER_SAVE";
export const POLICY_HOLDER_SAVE_RES = "POLICY_HOLDER_SAVE_RES";
export const CREATE_INSURANCE_ADDRESS = "CREATE_INSURANCE_ADDRESS";
export const CREATE_INSURANCE_ADDRESS_RES = "CREATE_INSURANCE_ADDRESS_RES";
export const SEARCH_PATIENT = "SEARCH_PATIENT";
export const SEARCH_PATIENT_RES = "SEARCH_PATIENT_RES";
export const SEARCH_FILTER = "SEARCH_FILTER";
