import React, { useEffect, useState } from "react";
import { Button, Grid, } from "@material-ui/core";
import Input from "../Input";
import Dropdown from "../Dropdown";
import states from "./states.json";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { dateTommddyyy } from "../../HelperFunctions/TimeDateTimestamp";
import {
  facilityList,
  patientDetails,
  providerList,
  savePatientDetails,
} from "../../Redux/Demographics/demographics.actions";
import toast from "react-hot-toast";
import VerifiedIcon from "@material-ui/icons/CheckCircleOutline";
import UnverifiedIcon from "@material-ui/icons/CancelOutlined";
import DobPicker from "../dobPicker";
import PhoneFormat from "../PhoneFormat";
import "./style.css";
function Demographics(props) {
  const rowData = props.rowData;
  const [verified, setVerified] = useState(0);
  const defaultForm = {
    active: 1,
    id: 0,
    clinicId: rowData.clinicId,
    mrn: "",
    firstName: "",
    middleName: "",
    lastName: "",
    addressLine1: "",
    addressLine2: "",
    city: "",
    state: "",
    zip: "",
    country: "US",
    dob: null,
    sex: null,
    maritalStatus: 1,
    phone: "",
    altPhone: "",
    emergencyPhone: "",
    emergencyContact: "",
    ssn: "",
    email: "",
    policyType: 0,
    locationId: 0,
    locationName: "",
    billingMethod: 0,
    balanceWriteoff: 0,
    sdob: "03/01/2023",
    pinNo: "",
    providerId: 0,
    providerName: "",
  };
  const [values, setValues] = useState(defaultForm);
  const [isEdit, setIsEdit] = useState(true);
  const [providerList, setProviderList] = useState([]);
  const [facilityList, setFacilityList] = useState([]);

  useEffect(() => {
    // let id = window.location.href.split("/").pop();
    let id = rowData.id;
    props.getPatientDetails(id);
    props.providerList({
      start: 0,
      limit: 14,
      orderByParameter: "fullName",
      orderBy: "asc",
      clinicId: rowData.clinicId,
    });
    props.facilityList({
      start: 0,
      limit: 14,
      orderByParameter: "locationName",
      orderBy: "asc",
      clinicId: rowData.clinicId,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  //validate dob
  const validateDate = (dob) => {
    const today = new Date();
    const inputDate = new Date(dob);
    return inputDate <= today;
  };
  const handleSumbit = (e) => {
    e.preventDefault();
    setIsEdit(true);
    let provider = providerList.find((x) => x.value === values.providerId);
    let facility = facilityList.find((x) => x.value === values.locationId);
    if (provider || facility) {
      props.savePatientDetails({
        ...values,
        providerName: provider.name,
        locationName: facility.name,
      });
    } else {
      props.savePatientDetails(values);
    }
  };

  const sentanceCase = (txt) => {
    return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
  };

  useEffect(() => {
    if (props.demographicsData.patientDetails) {
      let response = props.demographicsData.patientDetails.response;
      if (response.responseCode === 0) {
        let data = response.data;
        localStorage.setItem("patientInfo", JSON.stringify(data));
        data.firstName = sentanceCase(data.firstName);
        data.lastName = sentanceCase(data.lastName);
        data.city = sentanceCase(data.city);
        setValues(data);
        setVerified(data.active);
      }
    }
  }, [props.demographicsData.patientDetails]);

  // Function to calculate age from DOB
  const calculateAge = (dob) => {
    const dobDate = new Date(dob);
    const currentDate = new Date();
    const age = currentDate.getFullYear() - dobDate.getFullYear();
    const hasBirthdayOccurred =
      currentDate.getMonth() > dobDate.getMonth() ||
      (currentDate.getMonth() === dobDate.getMonth() &&
        currentDate.getDate() >= dobDate.getDate());
    return hasBirthdayOccurred ? age : age - 1;
  };

  useEffect(() => {
    if (
      props.demographicsData.savePatientDetails &&
      props.demographicsData.savePatientDetails.response
    ) {
      let response = props.demographicsData.savePatientDetails.response;
      if (response.responseCode === 0) {
        // console.log(response);
        toast.success("Patient details updated successfully");
        props.demographicsData.savePatientDetails = null;
        // let id = window.location.href.split("/").pop();
        const id = rowData.id;
        props.getPatientDetails(id);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.demographicsData.savePatientDetails]);

  const handleEdit = () => {
    setIsEdit(false);
  };

  useEffect(() => {
    if (props.demographicsData.providerList) {
      let response = props.demographicsData.providerList.response;
      if (response.responseCode === 0) {
        let provider = [];
        response.data.results.forEach((x) => {
          provider.push({
            name: x.fullName,
            value: x.id,
          });
        });
        setProviderList(provider);
      }
    }
  }, [props.demographicsData.providerList]);

  useEffect(() => {
    if (props.demographicsData.facilityList) {
      let response = props.demographicsData.facilityList.response;
      if (response.responseCode === 0) {
        let facility = [];
        response.data.results.forEach((x) => {
          facility.push({
            name: x.locationAlias,
            value: x.id,
          });
        });
        setFacilityList(facility);
      }
    }
  }, [props.demographicsData.facilityList]);

  const handleClick = () => {
    setVerified(!verified);
    props.savePatientDetails({
      ...values,
      active: values.active === 1 ? 0 : 1,
    });
  };
  //

  //  const handleDateChange = (e) => {
  //    const inputDate = e.target.value;
  //    if (validateDOB(inputDate)) {
  //      setValues({
  //        ...values,
  //        dob: inputDate,
  //      });
  //    } else {
  //      toast.error("DOB cannot be a future date.");
  //    }
  //  };
  // console.log(verified, "verified");
  return (
    <div className="demographics_tab_container">
      <div className="demographics_tab_form">
        <form className="demographics_tab_form_border" onSubmit={handleSumbit}>
          <div className="demographics_tab_form--innerWrapper">
            <div className="demographics_heading">
              Patient Information
              <div className="form_buttons">
                {isEdit && (
                  <>
                    <Button
                      variant="contained"
                      color="primary"
                      style={{ borderRadius: "10px" }}
                      startIcon={
                        verified === 1 ? <VerifiedIcon /> : <UnverifiedIcon />
                      }
                      onClick={handleClick}
                    >
                      {verified === 1 ? "Verified" : "Unverified"}
                    </Button>
                    <Button
                      variant="contained"
                      color="primary"
                      onClick={handleEdit}
                      style={{ borderRadius: "10px" }}
                    >
                      Edit
                    </Button>
                  </>
                )}
                {!isEdit && (
                  <>
                    <Button
                      variant="contained"
                      color="primary"
                      startIcon={
                        verified === 1 ? <VerifiedIcon /> : <UnverifiedIcon />
                      }
                      onClick={handleClick}
                    >
                      {verified === 1 ? "Verified" : "Unverified"}
                    </Button>
                    <Button
                      variant="outlined"
                      color="primary"
                      onClick={() => setIsEdit(true)}
                      style={{ borderRadius: "10px" }}
                    >
                      Cancel
                    </Button>
                    <Button
                      variant="contained"
                      color="primary"
                      // onClick={() => handleSumbit()}
                      style={{ borderRadius: "10px" }}
                      type="submit"
                    >
                      Save
                    </Button>
                  </>
                )}
              </div>
            </div>
            <div style={{ padding: "1rem" }}>
              {isEdit && (
                <Grid container spacing={2}>
                  <Grid container spacing={2}>
                    <Grid item xs={3}>
                      <Input
                        required
                        type="text"
                        label="First Name"
                        name="firstName"
                        value={values.firstName}
                        InputProps={{ disableUnderline: true }}
                      />
                    </Grid>
                    <Grid item xs={3}>
                      <Input
                        type="text"
                        label="Middle Name"
                        name="middleName"
                        value={values.middleName ? values.middleName : ""}
                        InputProps={{ disableUnderline: true }}
                      />
                    </Grid>
                    <Grid item xs={3}>
                      <Input
                        required
                        type="text"
                        label="Last Name"
                        name="lastName"
                        value={values.lastName ? values.lastName : ""}
                        InputProps={{ disableUnderline: true }}
                      />
                    </Grid>
                    <Grid item xs={3}>
                      <Input
                        required
                        label="DOB"
                        value={values.dob ? dateTommddyyy(values.dob) : ""}
                        InputProps={{ disableUnderline: true }}
                      />
                    </Grid>
                  </Grid>

                  <Grid container spacing={2}>
                    <Grid item xs={3}>
                      <Input
                        label="Age"
                        value={values.dob ? calculateAge(values.dob) + "Y" : ""}
                        InputProps={{ disableUnderline: true }}
                      />
                    </Grid>
                    <Grid item xs={3}>
                      <Input
                        label="Gender"
                        value={
                          values.sex === 1
                            ? "Male"
                            : values.sex === 2
                            ? "Female"
                            : ""
                        }
                        InputProps={{ disableUnderline: true }}
                      />
                    </Grid>
                    <Grid item xs={3}>
                      <Input
                        required
                        type="text"
                        label="Address Line 1"
                        value={values.addressLine1 ? values.addressLine1 : ""}
                        name="addressLine"
                        InputProps={{ disableUnderline: true }}
                      />
                    </Grid>
                    <Grid item xs={3}>
                      <Input
                        type="text"
                        label="Address Line 2"
                        value={values.addressLine2 ? values.addressLine2 : ""}
                        name="addressLine"
                        InputProps={{ disableUnderline: true }}
                      />
                    </Grid>
                  </Grid>

                  <Grid container spacing={2}>
                    <Grid item xs={3}>
                      <Input
                        type="text"
                        label="City, St"
                        value={values.city + ", " + values.state}
                        name="city"
                        InputProps={{ disableUnderline: true }}
                      />
                    </Grid>
                    <Grid item xs={3}>
                      <Input
                        label="Zip"
                        value={values.zip}
                        InputProps={{ disableUnderline: true }}
                      />
                    </Grid>
                    <Grid item xs={3}>
                      <Input
                        label="Cell"
                        value={values.phone ? values.phone : ""}
                        InputProps={{ disableUnderline: true }}
                      />
                      {/* <PhoneFormat/> */}
                    </Grid>
                    <Grid item xs={3}>
                      <Input
                        label="Alternate Phone"
                        value={values.altPhone ? values.altPhone : ""}
                        InputProps={{ disableUnderline: true }}
                      />
                    </Grid>
                  </Grid>

                  <Grid container spacing={2}>
                    <Grid item xs={3}>
                      <Input
                        type="email"
                        label="Email"
                        value={values.email}
                        name="email"
                        InputProps={{ disableUnderline: true }}
                      />
                    </Grid>
                    <Grid item xs={3}>
                      <Input
                        label="Account No."
                        value={values.mrn}
                        InputProps={{ disableUnderline: true }}
                      />
                    </Grid>
                    <Grid item xs={3}>
                      <Input
                        label="Facility"
                        value={values.locationName ? values.locationName : ""}
                        InputProps={{ disableUnderline: true }}
                      />
                    </Grid>
                    <Grid item xs={3}>
                      <Input
                        label="Provider"
                        value={values.providerName}
                        InputProps={{ disableUnderline: true }}
                      />
                    </Grid>
                  </Grid>

                  <Grid container spacing={2}>
                    <Grid item xs={3}>
                      <Input
                        label="Patient Balance"
                        value={""}
                        InputProps={{ disableUnderline: true }}
                      />
                    </Grid>
                    <Grid item xs={3}>
                      <Input
                        label="Insurance Balance"
                        value={""}
                        InputProps={{ disableUnderline: true }}
                      />
                    </Grid>
                  </Grid>
                </Grid>
              )}
              {!isEdit && (
                <Grid container spacing={2}>
                  <Grid container spacing={2}>
                    <Grid item xs={3}>
                      <Input
                        required
                        type="text"
                        label="First Name"
                        name="firstName"
                        variant="outlined"
                        value={values.firstName}
                        onChange={(e) =>
                          setValues({ ...values, firstName: e.target.value })
                        }
                      />
                    </Grid>
                    <Grid item xs={3}>
                      <Input
                        type="text"
                        label="Middle Name"
                        name="middleName"
                        variant="outlined"
                        value={values.middleName}
                        onChange={(e) =>
                          setValues({ ...values, middleName: e.target.value })
                        }
                      />
                    </Grid>
                    <Grid item xs={3}>
                      <Input
                        required
                        type="text"
                        label="Last Name"
                        name="lastName"
                        variant="outlined"
                        value={values.lastName}
                        onChange={(e) =>
                          setValues({ ...values, lastName: e.target.value })
                        }
                      />
                    </Grid>
                    <Grid item xs={3}>
                      <DobPicker
                        required
                        maxDate={new Date()}
                        label="DOB"
                        variant="outlined"
                        value={values.dob ? dateTommddyyy(values.dob) : ""}
                        onChange={(e) =>
                          setValues({ ...values, dob: e.target.value })
                        }
                      />
                      {/* <MuiPickersUtilsProvider utils={DateFnsUtils}>
                        <DatePicker
                          disableFuture
                          fullWidth
                          KeyboardButtonProps={{
                            "aria-label": "change date",
                          }}
                          InputLabelProps={{
                            shrink: true,
                          }}
                          value={values.dob ? dateTommddyyy(values.dob) : ""}
                          maxDate={new Date()}
                          {...props}
                          onChange={(date) =>{
                            
                            setValues({ ...values, dob: date })}
                          }
                          variant={"inline"}
                          inputVariant={
                            props.inputVariant ? props.inputVariant : "outlined"
                          }
                          format="MM/dd/yyyy"
                          placeholder={"MM/DD/YYYY"}
                          showTodayButton={true}
                          autoOk={true}
                        />
                      </MuiPickersUtilsProvider> */}
                    </Grid>
                  </Grid>

                  <Grid container spacing={2}>
                    <Grid item xs={3}>
                      <Dropdown
                        label="Gender"
                        variant="outlined"
                        options={[
                          { name: "Male", value: 1 },
                          { name: "Female", value: 2 },
                        ]}
                        value={values.sex}
                        onChange={(e) =>
                          setValues({ ...values, sex: e.target.value })
                        }
                      />
                    </Grid>
                    <Grid item xs={3}>
                      <Input
                        required
                        type="text"
                        label="Address Line 1"
                        name="addressLine"
                        variant="outlined"
                        value={values.addressLine1}
                        onChange={(e) =>
                          setValues({ ...values, addressLine1: e.target.value })
                        }
                      />
                    </Grid>
                    <Grid item xs={3}>
                      <Input
                        type="text"
                        label="Address Line 2"
                        name="addressLine"
                        variant="outlined"
                        value={values.addressLine2}
                        onChange={(e) =>
                          setValues({ ...values, addressLine2: e.target.value })
                        }
                      />
                    </Grid>
                    <Grid item xs={3}>
                      <Input
                        label="City"
                        variant="outlined"
                        value={values.city}
                        onChange={(e) =>
                          setValues({ ...values, city: e.target.value })
                        }
                      />
                    </Grid>
                  </Grid>

                  <Grid container spacing={2}>
                    <Grid item xs={3}>
                      <Dropdown
                        label="State"
                        variant="outlined"
                        options={states}
                        value={values.state}
                        onChange={(e) =>
                          setValues({ ...values, state: e.target.value })
                        }
                      />
                    </Grid>
                    <Grid item xs={3}>
                      <Input
                        label="Zip"
                        variant="outlined"
                        value={values.zip}
                        onChange={(e) =>
                          setValues({ ...values, zip: e.target.value })
                        }
                      />
                    </Grid>
                    <Grid item xs={3}>
                      <PhoneFormat
                        label="Cell"
                        variant="outlined"
                        value={values.phone}
                        onChange={(e) =>
                          setValues({ ...values, phone: e.target.value })
                        }
                      />
                    </Grid>
                    <Grid item xs={3}>
                      <PhoneFormat
                        label="Alternate Phone"
                        variant="outlined"
                        value={values.altPhone}
                        onChange={(e) =>
                          setValues({ ...values, altPhone: e.target.value })
                        }
                      />
                    </Grid>
                  </Grid>
                  <Grid container spacing={2}>
                    <Grid item xs={3}>
                      <Input
                        type="email"
                        label="Email"
                        name="email"
                        variant="outlined"
                        value={values.email}
                        onChange={(e) =>
                          setValues({ ...values, email: e.target.value })
                        }
                      />
                    </Grid>
                    <Grid item xs={3}>
                      <Input
                        label="Account No."
                        variant="outlined"
                        value={values.mrn}
                        onChange={(e) =>
                          setValues({ ...values, mrn: e.target.value })
                        }
                      />
                    </Grid>
                    <Grid item xs={3}>
                      <Dropdown
                        options={facilityList}
                        label="Facility"
                        variant="outlined"
                        value={values.locationId}
                        onChange={(e) =>
                          setValues({ ...values, locationId: e.target.value })
                        }
                      />
                    </Grid>
                    <Grid item xs={3}>
                      <Dropdown
                        options={providerList}
                        label="Provider"
                        variant="outlined"
                        value={values.providerId}
                        onChange={(e) =>
                          setValues({ ...values, providerId: e.target.value })
                        }
                      />
                    </Grid>
                  </Grid>
                </Grid>
              )}
            </div>
          </div>
        </form>
      </div>
    </div>
  );
}

const mapStateToProps = (state) => ({
  demographicsData: state.demographics,
});

const mapDispatchToProps = (dispatch) => ({
  getPatientDetails: (values) => dispatch(patientDetails(values)),
  savePatientDetails: (values) => dispatch(savePatientDetails(values)),
  facilityList: (values) => dispatch(facilityList(values)),
  providerList: (values) => dispatch(providerList(values)),
});
Demographics.propTypes = {
  getPatientDetails: PropTypes.func,
  savePatientDetails: PropTypes.func,
  facilityList: PropTypes.func,
  providerList: PropTypes.func,
};
export default connect(mapStateToProps, mapDispatchToProps)(Demographics);
