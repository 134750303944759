import React, { useEffect, useState } from "react";
import {
  Button,
  Dialog,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@material-ui/core";
import NoDataFound from "../../assets/nodata-found.png";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { Skeleton } from "@material-ui/lab";
import { formatDate } from "../../HelperFunctions/TimeDateTimestamp";
import "./style.css";
// import Dropdown from "../Dropdown";
import { updateClaimStatus } from "../../Redux/Demographics/demographics.actions";
import toast from "react-hot-toast";

const statuses = [
  { name: "Visit Completed", value: 1 },
  { name: "Created", value: 2 },
  { name: "Filed", value: 3 },
  { name: "Rejected", value: 4 },
  { name: "Denied", value: 5 },
  { name: "Sec Ready", value: 6 },
  { name: "Sec Pending", value: 7 },
  { name: "Ter Ready", value: 8 },
  { name: "Ter Pending", value: 9 },
  { name: "Pat Balance", value: 10 },
  { name: "Closed", value: 11 },
  { name: "Clar Opened", value: 12 },
  { name: "Clar Closed", value: 13 },
  { name: "On Hold", value: 14 },
];

function ClaimHistory(props) {
  const [rows, setRows] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    if (props.demographicsData.claimHistory) {
      let response = props.demographicsData.claimHistory.response;
      if (response.responseCode === 0) {
        let data = response.data.result.providerSummary;
        setRows(data);
        setLoading(false);
      }
    }
  }, [props.demographicsData.claimHistory]);

  const handleUpdate = (e, x, i) => {
    // const rowData = JSON.parse(localStorage.getItem("RowData"));
    const rowData = props.currentRow;
    let row = rows;
    row[i].claimStatus = e.target.value;
    setRows([...row]);
    props.updateClaimStatus({
      clinicId: rowData.clinicId,
      claimId: x.claimId,
      claimStatus: e.target.value,
    });
  };

  useEffect(() => {
    if (props.demographicsData.updateClaimStatus) {
      let response = props.demographicsData.updateClaimStatus.response;
      // console.log(response);
      if (response.responseCode === 0) {
        toast.success("Claim Status Update Successfully");
        props.demographicsData.updateClaimStatus = null;
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.demographicsData.updateClaimStatus]);

  return (
    <Dialog
      onClose={() => props.handleClose()}
      open={props.open}
      fullWidth={true}
      maxWidth={"lg"}
    >
      <div className="claim_history">
        <div style={{ display: "flex", justifyContent: "space-between" }}>
          <div className="dialog_title">Claim History</div>
          <div className="claim_history_btns">
            <Button
              color="primary"
              variant="outlined"
              onClick={() => props.handleClose()}
            >
              Cancel
            </Button>
          </div>
        </div>
        {loading ? (
          <TableContainer
            style={{ border: "1px solid #dbdbdb", borderRadius: "10px" }}
          >
            <Table>
              <TableHead style={{ backgroundColor: "#f2fbf9" }}>
                <TableRow>
                  {[...Array(11)].map((_, i) => (
                    <TableCell key={i}>
                      <Skeleton />
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {[...Array(2)].map((_, i) => (
                  <TableRow key={i}>
                    {[...Array(11)].map((_, j) => (
                      <TableCell key={j}>
                        <Skeleton />
                      </TableCell>
                    ))}
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        ) : rows.length > 0 ? (
          <TableContainer style={{ height: 600 }}>
            <Table>
              <TableHead>
                <TableRow>
                  {[
                    "DOS",
                    "Provider",
                    "Payor",
                    "Facility",
                    "Service",
                    "Charges",
                    "Payments",
                    "Ins Bal",
                    "Pat Bal",
                    "Claim Status",
                  ].map((x, i) => (
                    <TableCell
                      key={i}
                      className={"data_table_list_cell"}
                      align={"left"}
                      sortDirection={false}
                    >
                      {x}
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {rows.map((x, i) => (
                  <TableRow key={i}>
                    <TableCell className={"data_table_cell"} align="left">
                      {formatDate( x.sdos)}
                    </TableCell>
                    <TableCell className={"data_table_cell"} align="left">
                      {x.sproviderName}
                    </TableCell>
                    <TableCell className={"data_table_cell"} align="left">
                      {x.payorName.substring(0, 15)}
                    </TableCell>
                    <TableCell className={"data_table_cell"} align="left">
                      {x.facilityName.substring(0, 20)}
                    </TableCell>
                    <TableCell className={"data_table_cell"} align="left">
                      {x.serviceName}
                    </TableCell>
                    <TableCell className={"data_table_cell"} align="left">
                      ${x.dcharges}
                    </TableCell>
                    <TableCell className={"data_table_cell"} align="left">
                      ${x.dpayments}
                    </TableCell>
                    <TableCell className={"data_table_cell"} align="left">
                      ${x.insuranceBalance}
                    </TableCell>
                    <TableCell className={"data_table_cell"} align="left">
                      ${x.patientBalance}
                    </TableCell>
                    <TableCell
                      className={"data_table_cell status"}
                      align="left"
                    >
                      {x.claimStatus ? statuses[x.claimStatus - 1].name : ""}
                      {/* <Dropdown
                        className={"status_dropdown"}
                        value={x.claimStatus}
                        options={statuses}
                        onChange={(e) => handleUpdate(e, x, i)}
                      /> */}
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        ) : (
          <div className="nodatafound">
            <img src={NoDataFound} alt={"NoDataFound"} />
          </div>
        )}
      </div>
    </Dialog>
  );
}
const mapStateToProps = (state) => ({
  demographicsData: state.demographics,
});

const mapDispatchToProps = (dispatch) => ({
  updateClaimStatus: (values) => dispatch(updateClaimStatus(values)),
});
ClaimHistory.propTypes = {
  claimHistory: PropTypes.func,
  updateClaimStatus: PropTypes.func,
};

export default connect(mapStateToProps, mapDispatchToProps)(ClaimHistory);
