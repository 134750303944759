// import React, { useEffect, useState } from "react";
// import {
//   Button,
//   Dialog,
//   DialogContent,
//   Typography,
//   makeStyles,
// } from "@material-ui/core";
// import CsvIcon from "../../assets/csv-icon.svg";
// import GetIcon from "../../assets/download.svg";
// import {
//   fileDownload,
//   fileDownloadResponse,
//   fileList,
// } from "../../Redux/Demographics/demographics.actions";
// import PropTypes from "prop-types";
// import { connect } from "react-redux";
// import NoDataFound from "../../assets/nodata-found.png";
// import "./style.css";

// const useStyles = makeStyles((theme) => ({
//   root: {
//     width: "100%",
//   },
//   heading: {
//     fontSize: theme.typography.pxToRem(20),
//     fontWeight: "500 !important",
//     paddingBottom: "1rem !important",
//   },

//   fileListItem: {
//     border: "1px solid #D7E0E9",
//     background: "#F2F2F2",
//     display: "flex",
//     alignItems: "center",
//     borderRadius: "8px",
//     paddingRight: "10px",
//   },
//   savedFiles: {
//     display: "flex",
//     alignItems: "center",
//     flexWrap: "wrap",
//     margin: "16px 0",
//     gap: "16px",
//   },
// }));

// function UploadList(props) {
//   const classes = useStyles();
//   const userData = JSON.parse(localStorage.getItem("userData"));

//   const [files, setFiles] = useState([]);
//   const [currentFile, setCurrentFile] = useState("");
//   const [isPreview, setIsPreview] = useState(false);
//   const [isPreviewOpen, setIsPreviewOpen] = useState(false);
//   const [previewFileUrl, setPreviewFileUrl] = useState("");
//   useEffect(() => {
//     // let id = window.location.href.split("/").pop();
//     props.fileList({
//       userId: userData.userId,
//       userName: userData.fullName,
//       patientId: props.rowData.id,
//     });
//     // eslint-disable-next-line react-hooks/exhaustive-deps
//   }, []);

//   useEffect(() => {
//     if (props.demographicsData.fileList) {
//       let response = props.demographicsData.fileList.response;
//       if (response.responseCode === 0) {
//         let data = response.data.results;
//         setFiles(data);
//       }
//     }
//   }, [props.demographicsData.fileList]);

//   const handleFileDownload = (x, type) => {
//     if (type === "preview") {
//       setIsPreview(true);
//     } else {
//       setIsPreview(false);
//     }
//     props.fileDownload({
//       fileId: x.imageId,
//       fileName: x.fileName,
//       image_type: x.imageTypeId,
//     });
//     setCurrentFile(x.fileName);
//   };

//   const downloadFiles = (content, fileName, contentType) => {
//     if (!contentType) contentType = "application/octet-stream";
//     var a = document.createElement("a");
//     var blob = new Blob([content], { type: contentType });
//     a.href = URL.createObjectURL(blob);
//     a.download = fileName;
//     a.click();
//     blob = null;
//   };

//   useEffect(() => {
//     if (props.demographicsData.fileDownload) {
//       if (isPreview) {
//         // console.log(props.demographicsData.fileDownload,"flie");
//         const previewFile = props.demographicsData.fileDownload.response;
//         const blob = new Blob([previewFile], { type: "image/jpg" });
//         const previewUrl = URL.createObjectURL(blob);
//         setIsPreviewOpen(true);
//         setPreviewFileUrl(previewUrl);
//         props.fileDownloadResponse(null);
//         setIsPreview(false);
//       } else {
//         let blobData = props.demographicsData.fileDownload.response;
//         let fileName = currentFile;
//         let fileType = currentFile.split(".")[1];
//         downloadFiles(blobData, fileName, fileType);
//         props.fileDownloadResponse(null);
//       }
//     }
//     // eslint-disable-next-line react-hooks/exhaustive-deps
//   }, [props.demographicsData.fileDownload]);

//   const filePreview = () => {
//     if (props.demographicsData.fileDownload) {
//       const previewFile = props.demographicsData.fileDownload.response;
//       const blob = new Blob([previewFile], { type: "application/jpg" });
//       const previewUrl = URL.createObjectURL(blob);
//       setIsPreviewOpen(true);
//       setPreviewFileUrl(previewUrl);
//       props.demographicsData.fileDownload = null;
//     }
//   };
//   return (
//     <div className={"uploadList_container"}>
//       <div className="upload_heading">Files</div>
//       <div className={classes.dropzoneContainer}>
//         <div className={classes.savedFiles}>
//           {files.length > 0 ? (
//             files.map((x, i) => (
//               <>
//                 <div
//                   key={i}
//                   className={classes.fileListItem}
//                   onClick={() => handleFileDownload(x)}
//                   style={{ padding: "5px" }}
//                 >
//                   <img
//                     src={CsvIcon}
//                     alt=""
//                     style={{
//                       color: "#000000",
//                       cursor: "pointer",
//                       height: "20px",
//                       paddingRight: "5px",
//                     }}
//                   />
//                   <Typography>{x.fileName}</Typography>
//                   <img
//                     src={GetIcon}
//                     alt={"hh"}
//                     style={{
//                       color: "#000000",
//                       cursor: "pointer",
//                       height: "21px",
//                       paddingLeft: "5px",
//                     }}
//                   />
//                 </div>
//                 <Button
//                   variant="outlined"
//                   color="primary"
//                   // style={{ borderRadius: "10px" }}
//                   onClick={() => handleFileDownload(x, "preview")}
//                 >
//                   preview
//                 </Button>
//               </>
//             ))
//           ) : (
//             <div>
//               <img
//                 style={{ height: "200px", width: "100%", padding: "0 7rem" }}
//                 src={NoDataFound}
//                 alt=""
//               />
//             </div>
//           )}
//         </div>
//       </div>
//       <Dialog
//         className="scrollbarY--custom"
//         maxWidth="md"
//         //fullScreen
//         fullWidth
//         open={isPreviewOpen}
//       >
//         <DialogContent className="previewDialogContent">
//           <div style={{ display: "flex", flexDirection: "row-reverse" }}>
//             <div className="preview_closeBtn">
//               <button onClick={() => setIsPreviewOpen(false)}>close</button>
//             </div>
//           </div>
//           <div className="preview">
//             <object
//               menu="false"
//               width="100%"
//               height="83%"
//               data={previewFileUrl}
//               type="application/pdf"
//             ></object>
//           </div>
//         </DialogContent>
//       </Dialog>
//     </div>
//   );
// }

// const mapStateToProps = (state) => ({
//   demographicsData: state.demographics,
// });

// const mapDispatchToProps = (dispatch) => ({
//   fileList: (values) => dispatch(fileList(values)),
//   fileDownload: (values) => dispatch(fileDownload(values)),
//   fileDownloadResponse: (values) => dispatch(fileDownloadResponse(values)),
// });
// UploadList.propTypes = {
//   fileList: PropTypes.func,
//   fileDownload: PropTypes.func,
//   fileDownloadResponse: PropTypes.func,
// };

// export default connect(mapStateToProps, mapDispatchToProps)(UploadList);

import React, { useEffect, useState } from "react";
import {
  Button,
  Dialog,
  DialogContent,
  Tooltip,
  Typography,
  makeStyles,
} from "@material-ui/core";
import CsvIcon from "../../assets/csv-icon.svg";
import GetIcon from "../../assets/download.svg";
import {
  fileDownload,
  fileDownloadResponse,
  fileList,
} from "../../Redux/Demographics/demographics.actions";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import NoDataFound from "../../assets/nodata-found.png";
import "./style.css";
import VisibilityIcon from "@material-ui/icons/Visibility";
import CloseIcon from "@material-ui/icons/Close";
const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
  },
  heading: {
    fontSize: theme.typography.pxToRem(20),
    fontWeight: "500 !important",
    paddingBottom: "1rem !important",
  },
  fileListItem: {
    border: "1px solid #D7E0E9",
    background: "#F2F2F2",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    borderRadius: "8px",
    paddingRight: "10px",
  },
  savedFiles: {
    display: "flex",
    alignItems: "center",
    flexWrap: "wrap",
    margin: "16px 0",
    gap: "16px",
  },
  previewImage: {
    width: "100%",
    height: "auto",
  },
  previewError: {
    height: "600px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "column",
    fontSize: "16px",
  },
}));

function UploadList(props) {
  const classes = useStyles();
  const userData = JSON.parse(localStorage.getItem("userData"));

  const [files, setFiles] = useState([]);
  const [currentFile, setCurrentFile] = useState("");
  const [isPreview, setIsPreview] = useState(false);
  const [isPreviewOpen, setIsPreviewOpen] = useState(false);
  const [previewFileUrl, setPreviewFileUrl] = useState("");

  // Fetch the file list when the component mounts
  useEffect(() => {
    props.fileList({
      userId: userData.userId,
      userName: userData.fullName,
      patientId: props.rowData.id,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // Update the files state when the file list response changes
  useEffect(() => {
    if (props.demographicsData.fileList) {
      let response = props.demographicsData.fileList.response;
      if (response.responseCode === 0) {
        // let data = response.data.results;
        let data = response.data; //api format changed `response.data.results` to `response.data`

        setFiles(data);
      }
    }
  }, [props.demographicsData.fileList]);

  // Handle file download and preview (dowloadApi)
  const handleFileDownload = (x, type) => {
    if (type === "preview") {
      setIsPreview(true);
    } else {
      setIsPreview(false);
    }
    props.fileDownload({
      fileId: x.fileId,
      fileName: x.fileName,
      isInternal: x.isInternal,
      // image_type: x.imageTypeId,
    });
    setCurrentFile(x.fileName);
  };

  // Helper function to download files
  const downloadFiles = (content, fileName, contentType) => {
    if (!contentType) contentType = "application/octet-stream";
    var a = document.createElement("a");
    var blob = new Blob([content], { type: contentType });
    a.href = URL.createObjectURL(blob);
    a.download = fileName;
    a.click();
    blob = null;
  };

  // Handle file download or preview based on the state
  useEffect(() => {
    if (props.demographicsData.fileDownload) {
      if (isPreview) {
        const previewFile = props.demographicsData.fileDownload.response;
        const blob = new Blob([previewFile], {
          type: getMimeType(currentFile),
        });
        const previewUrl = URL.createObjectURL(blob);
        setIsPreviewOpen(true);
        setPreviewFileUrl(previewUrl);
        props.fileDownloadResponse(null);
        setIsPreview(false);
      } else {
        let blobData = props.demographicsData.fileDownload.response;
        let fileName = currentFile;
        let fileType = currentFile.split(".")[1];
        downloadFiles(blobData, fileName, fileType);
        props.fileDownloadResponse(null);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.demographicsData.fileDownload]);

  // Function to determine the MIME type based on the file extension
  const getMimeType = (fileName) => {
    const extension = fileName.split(".").pop();
    switch (extension) {
      case "jpg":
      case "jpeg":
        return "image/jpeg";
      case "png":
        return "image/png";
      case "pdf":
        return "application/pdf";
      default:
        return "application/octet-stream";
    }
  };

  // Close the preview modal
  const handlePreviewClose = () => {
    setIsPreviewOpen(false);
    setPreviewFileUrl("");
  };

  return (
    <div className={"uploadList_container"}>
      <div className="upload_heading">Files</div>
      <div className={classes.dropzoneContainer}>
        <div className={classes.savedFiles}>
          {files?.length > 0 ? (
            files.map((x, i) => (
              <>
                <div
                  key={i}
                  className={classes.fileListItem}
                  style={{ padding: "5px" }}
                >
                  <img
                    src={CsvIcon}
                    alt=""
                    style={{
                      color: "#000000",
                      // cursor: "pointer",
                      height: "20px",
                      paddingRight: "5px",
                    }}
                  />
                  <Typography>{x.fileName}</Typography>
                  <Tooltip title={"Download"}>
                    <img
                      src={GetIcon}
                      alt={"hh"}
                      onClick={() => handleFileDownload(x)}
                      style={{
                        color: "#000000",
                        cursor: "pointer",
                        height: "21px",
                        paddingLeft: "5px",
                      }}
                    />
                  </Tooltip>
                  <Button
                    variant="text"
                    color="primary"
                    onClick={() => handleFileDownload(x, "preview")}
                  >
                    <Tooltip title={"Preview"}>
                      <VisibilityIcon fontSize="small" />
                    </Tooltip>
                  </Button>
                </div>
              </>
            ))
          ) : (
            <div>
              <img
                style={{ height: "200px", width: "100%", padding: "0 7rem" }}
                src={NoDataFound}
                alt=""
              />
            </div>
          )}
        </div>
      </div>
      <Dialog
        className="scrollbarY--custom"
        maxWidth="md"
        fullWidth
        open={isPreviewOpen}
        onClose={handlePreviewClose}
      >
        <DialogContent className="previewDialogContent">
          <div style={{ display: "flex", flexDirection: "row-reverse" }}>
            <div className="preview_closeBtn">
              <Button
                variant="text"
                size="small"
                color="primary"
                onClick={handlePreviewClose}
              >
                <CloseIcon />
              </Button>
            </div>
          </div>
          <div className="preview">
            {getMimeType(currentFile) === "application/pdf" ? (
              <object
                aria-label="Preview of the selected file"
                menu="false"
                width="100%"
                height="900px"
                data={previewFileUrl}
                type="application/pdf"
              ></object>
            ) : getMimeType(currentFile) === "image/jpeg" ||
              getMimeType(currentFile) === "image/png" ? (
              <img
                src={previewFileUrl}
                alt="File preview "
                className={classes.previewImage}
              />
            ) : (
              <div className={classes.previewError}>
                {/* <h3>Oopz..</h3> */}
                <span>Preview not available for this file type.</span>
              </div>
            )}
          </div>
        </DialogContent>
      </Dialog>
    </div>
  );
}

const mapStateToProps = (state) => ({
  demographicsData: state.demographics,
});

const mapDispatchToProps = (dispatch) => ({
  fileList: (values) => dispatch(fileList(values)),
  fileDownload: (values) => dispatch(fileDownload(values)),
  fileDownloadResponse: (values) => dispatch(fileDownloadResponse(values)),
});

UploadList.propTypes = {
  fileList: PropTypes.func,
  fileDownload: PropTypes.func,
  fileDownloadResponse: PropTypes.func,
};

export default connect(mapStateToProps, mapDispatchToProps)(UploadList);
