import React, { useCallback, useEffect, useMemo, useState } from "react";
import { Button, Typography, makeStyles } from "@material-ui/core";
import CsvIcon from "../../assets/csv-icon.svg";
import DeleteIcon from "@material-ui/icons/Delete";
import { useDropzone } from "react-dropzone";
import CircularProgress from "@material-ui/core/CircularProgress";
import CloudUpload from "../../assets/cloud.svg";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import {
  fileList,
  fileUpload,
} from "../../Redux/Demographics/demographics.actions";
import "./style.css";
import toast from "react-hot-toast";

const useStyles = makeStyles({
  dropzoneContainer: {
    margin: "20px 10px",
    // width: "100%",
    backgroundColor: "#fff",
    borderRadius: "8px",
    padding: "2rem",
  },
  h6: {
    fontSize: "1.2rem !important",
    textAlign: "center",
  },
  fileListItem: {
    border: "1px solid #D7E0E9",
    background: "#F2F2F2",
    display: "flex",
    alignItems: "center",
    // gap: "1rem",
    borderRadius: "8px",
    paddingRight: "10px",
    // cursor: 'pointer',
  },
  fileListItemContainer: {
    display: "flex",
    // justifyContent: "space-between",
    alignItems: "center",
    margin: "16px 0",
    gap: "5px",
  },
  savedFiles: {
    display: "flex",
    alignItems: "center",
    margin: "0 2rem",
    gap: "5px",
  },
  buttonDiv: {
    display: "flex",
    flexDirection: "row-reverse",
    paddingTop: "10px",
  },
  emrdiv: {
    width: "173px",
    marginRight: "10px",
  },
  iconContainer: {
    height: 110,
  },
  heading: {
    fontSize: "20px",
    fontWeight: "500 !important",
  },
});

const baseStyle = {
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  padding: "35px",
  borderWidth: 2,
  borderColor: "#808080",
  borderStyle: "dashed",
  backgroundColor: "rgba(208, 239, 239, 0.27)",
  color: "var(--color-grey-900)",
  fontWeight: "500",
  transition: "border .3s ease-in-out",
  borderRadius: "6px",
};

const activeStyle = {
  borderColor: "#2196f3",
};

const acceptStyle = {
  borderColor: "var(--Secondary-green)",
  backgroundColor: "rgba(208, 239, 239, 0.8)",
};

const rejectStyle = {
  borderColor: "rgba(255,85, 85, 0.7)",
  backgroundColor: "rgba(255,0, 13, 0.03)",
};

function FileUploader(props) {
  const classes = useStyles();
  const [files, setFiles] = useState([]);
  const [status, setStatus] = useState("view");

  const onDrop = useCallback((acceptedFiles) => {
    setFiles(acceptedFiles);
  }, []);

  const {
    getRootProps,
    getInputProps,
    isDragActive,
    isDragAccept,
    isDragReject,
  } = useDropzone({
    onDrop,
    // accept: "image/*",
    minSize: 0,
    multiple: false,
  });

  const style = useMemo(
    () => ({
      ...baseStyle,
      ...(isDragActive ? activeStyle : {}),
      ...(isDragAccept ? acceptStyle : {}),
      ...(isDragReject ? rejectStyle : {}),
    }),
    [isDragActive, isDragAccept, isDragReject]
  );
  //get file extention
  function getFileExtension(fileName) {
    return fileName.split(".").pop();
  }

  // File upload function
  const triggerUpload = () => {
    const patientInfo = JSON.parse(localStorage.getItem("patientInfo"));
    const verificationStatus = patientInfo?.active;

    if (files.length === 0) {
      toast.error("No file selected. Please select a file to upload.");
    } else if (verificationStatus === 0 || verificationStatus === 3) {
      toast.error("Patient is not active. Please check the patient's status.");
    } else {
      console.log("active");
      setStatus("uploading");
      const fileType = getFileExtension(files[0].name);
      const fileDto = {
        clinicId: props.rowData?.clinicId,
        patientId: props.rowData?.id,
        fileName: files[0].name,
        fileType: fileType,
      };
      const formData = new FormData();
      // data.append("Image", files[0]);
      // setting API payload in correct format
      formData.append("file", files[0]);
      formData.append("fileDto", JSON.stringify(fileDto));
      props.fileUpload({ data: formData });
      // props.fileUpload({ data: formData, patientId: props.rowData?.id });
    }
  };

  //Effect for handle File upload Res
  useEffect(() => {
    const userData = JSON.parse(localStorage.getItem("userData"));
    // let id = window.location.href.split("/").pop();
    if (props.demographicsData.fileUpload) {
      let response = props.demographicsData.fileUpload.response;
      if (response.responseCode === 0) {
        toast.success("File Uploaded Successfully");
        setStatus("view");
        setFiles([]);
        props.demographicsData.fileUpload = null;
        props.fileList({
          userId: userData.userId,
          userName: userData.fullName,
          patientId: props.rowData?.id,
        });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.demographicsData.fileUpload]);

  return (
    <div className={classes.dropzoneContainer}>
      <div>
        <div className="upload_button">
          <div className="file_uploader_heading">Upload Files</div>
          <Button
            type="submit"
            variant="contained"
            color="primary"
            onClick={triggerUpload}
            style={{ borderRadius: "10px" }}
          >
            Upload
          </Button>
        </div>
        <div className={classes.savedFiles}>
          {files.length > 0 &&
            files.map((x, i) => (
              <div key={i} className={classes.fileListItem}>
                <img
                  src={CsvIcon}
                  alt=""
                  style={{
                    color: "#000000",
                    cursor: "pointer",
                    height: "35px",
                  }}
                />

                <Typography>{x.name}</Typography>
                <DeleteIcon
                  style={{ color: "grey", cursor: "pointer" }}
                  onClick={() => setFiles([])}
                />
              </div>
            ))}
        </div>
      </div>
      <div style={{ padding: "2rem" }}>
        {status === "view" && (
          <div {...getRootProps({ style })}>
            <input {...getInputProps()} />
            <p> Drag and drop an image or click to upload files</p>
            <div className={classes.iconContainer}>
              <img
                src={CloudUpload}
                alt=""
                style={{ height: "100%", width: "100%" }}
              />
            </div>
            {!isDragActive && <div></div>}
            {isDragActive && !isDragReject && "Drop the file here"}
            {isDragReject &&
              "File type not accepted! Please upload an image file."}
          </div>
        )}
        {status === "uploading" && (
          <div style={{ textAlign: "center" }} className="upload_progression">
            <CircularProgress />
            <p>loading</p>
          </div>
        )}
      </div>
    </div>
  );
}

const mapStateToProps = (state) => ({
  demographicsData: state.demographics,
});

const mapDispatchToProps = (dispatch) => ({
  fileUpload: (values) => dispatch(fileUpload(values)),
  fileList: (values) => dispatch(fileList(values)),
});
FileUploader.propTypes = {
  fileUpload: PropTypes.func,
  fileList: PropTypes.func,
};
export default connect(mapStateToProps, mapDispatchToProps)(FileUploader);
