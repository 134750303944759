import React, { useState } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import TextField from '@material-ui/core/TextField'
import FormControl from '@material-ui/core/FormControl'
import MaskedInput from 'react-text-mask'
import PropTypes from 'prop-types'

const useStyles = makeStyles((theme) => ({
  formControl: {
    // margin: theme.spacing(1),
    minWidth: '100%',
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
}))

/**
 * Mask for Phone Number
 * @param {props} value from the parent compontent
 * @return {JSXElement}
 */
function TextMaskCustom(props) {
  const { inputRef, ...other } = props

  return (
    <MaskedInput
      {...other}
      ref={(ref) => {
        inputRef(ref ? ref.inputElement : null)
      }}
      mask={[
        '(',
        /[1-9]/,
        /\d/,
        /\d/,
        ')',
        ' ',
        /\d/,
        /\d/,
        /\d/,
        '-',
        /\d/,
        /\d/,
        /\d/,
        /\d/,
      ]}
      placeholderChar={'_'}
      //  showMask
    />
  )
}

TextMaskCustom.propTypes = {
  inputRef: PropTypes.func.isRequired,
}
/**
 * @fileOverview Manages Reusable Phone Number field.
 * @component
 * @author Cinu James <cinu@trillium-technologies.com>
 * @param {props} value from the parent component
 * @example
 * return(
 * <PhoneFormat/>
 * )
 */
function PhoneFormat(props) {
  const classes = useStyles()
  const [value, setValues] = useState(props.value ? props.value : '')
  /**
   * onChange of Phone Number field
   */
  const handleInput = (event) => {
    setValues(event.target.value)
    if (props.onChange) {
      props.onChange(event)
    }
  }
  return (
    <FormControl className={classes.formControl}>
      <TextField
        onChange={handleInput}
        helperText={props.helperText}
        fullWidth
        InputLabelProps={{ shrink: props.shrink && props.shrink }}
        InputProps={{
          inputComponent: TextMaskCustom,
        }}
        type={props.type ? props.type : 'text'}
        id="standard-basic"
        label={props.placeholder ? props.placeholder : ''}
        value={value}
        {...props}
        inputProps={{
          autoComplete: 'no',
        }}
      />
    </FormControl>
  )
}
export default PhoneFormat
