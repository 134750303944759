import React, { useEffect, useState } from "react";
import Dropdown from "../Dropdown";
import { Button } from "@material-ui/core";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import {
  getCache,
  setFilter,
} from "../../Redux/Demographics/demographics.actions";
import DobPicker from "../dobPicker";
import "./style.css";
import { dateToYyyyMmDd } from "../../HelperFunctions/TimeDateTimestamp";

function DataTableFilter(props) {
  let currentDate = new Date();
  const [clinicList, setClinicList] = useState([]);
  const [provider, setProvider] = useState([]);
  const [forms, setForms] = useState({
    clinic: null,
    provider: null,
    ptStatus: [],
    insStatus: [],
    owner: null,
    startDate: new Date(currentDate.getFullYear(), currentDate.getMonth(), 1),
    endDate: new Date(),
  });
  const [owner, setOwner] = useState([]);

  useEffect(() => {
    const cache = JSON.parse(localStorage.getItem("cache"));
    let clinicList = [];
    cache?.clinicDtoList?.forEach((x, i) => {
      if (i === 0) {
        clinicList.push({
          name: "All Clinic",
          value: null,
        });
      }
      clinicList.push({
        name: x.name,
        value: x.clinicId,
      });
    });
    setClinicList(clinicList);
  }, []);

  useEffect(() => {
    if (props.demographicsData.filterForm.clinic !== null) {
      const filter = props.demographicsData.filterForm;
      props.getCache(filter.clinic);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const clinicChange = (e) => {
    setForms({ ...forms, clinic: e.target.value });
    if (e.target.value !== null) {
      props.getCache(e.target.value);
    }
    else
    {
      setProvider([])
      setForms({...forms,clinic:null,provider:null})
    }
  };
// console.log(forms.providerId,"dfg")
  useEffect(() => {
    if (props.demographicsData.cache) {
      let response = props.demographicsData.cache.response;
      if (response.responseCode === 0) {
        let data = response.data;
        let provider = [];
        data.providerDtoList.forEach((x, i) => {
          if (i === 0) {
            provider.push({
              name: "All Providers",
              value: null,
            });
          }
          provider.push({
            name: x.fullName,
            value: x.id,
          });
        });
        if (forms.clinic) {
          setProvider(provider);
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.demographicsData.cache]);

  useEffect(() => {
    if (props.demographicsData.filterForm) {
      setForms({ ...props.demographicsData.filterForm });
    }
  }, [props.demographicsData.filterForm]);

  useEffect(() => {
    const cache = JSON.parse(localStorage.getItem("cache"));
    let owner = [];
    cache.userDtoList.forEach((x) => {
      owner.push({
        name: x.fullName,
        value: x.userId,
      });
    });
    setOwner(owner);
  }, []);
  useEffect(() => {
    localStorage.setItem("filterData", JSON.stringify({
      start:0,
      limit:50,
      dosStart:dateToYyyyMmDd(forms.startDate),
      dosEnd:dateToYyyyMmDd(forms.endDate),
    }));
  }, [forms.startDate, forms.endDate]);
  return (
    <div>
      <div className="filter_container_div">
        <div className="filter_field">
          <Dropdown
            label="Clinic"
            options={[...clinicList]}
            value={forms.clinic ? forms.clinic : ""}
            onChange={(e) => clinicChange(e)}
          />
        </div>
        <div className="filter_field">
          <Dropdown
            label="Provider"
            options={[...provider]}
            value={forms.provider ? forms.provider : ""}
            onChange={(e) => setForms({ ...forms, provider: e.target.value })}
          />
        </div>
        <div className="filter_field">
          <Dropdown
            label="Pt Status"
            options={[
              { name: "All", value: null },
              { name: "Active", value: 1 },
              { name: "Inactive", value: 0 },
              { name: "Pending", value: 3 },
            ]}
            value={forms.ptStatus}
            onChange={(e) => setForms({ ...forms, ptStatus: e.target.value })}
          />
        </div>
        <div className="filter_field">
          <Dropdown
            label="Ins Status"
            options={[
              { name: "All", value: null },
              { name: "Active", value: 1 },
              { name: "Inactive", value: 0 },
              { name: "Pending", value: 3 },
            ]}
            value={forms.insStatus}
            onChange={(e) => setForms({ ...forms, insStatus: e.target.value })}
          />
        </div>
        <div className="filter_field_date">
          <DobPicker
            label="DOS Start"
            inputVariant={"standard"}
            value={forms.startDate}
            onChange={(e) => setForms({ ...forms, startDate: e.target.value })}
          />
        </div>
        <div className="filter_field_date">
          <DobPicker
            label="DOS End"
            inputVariant={"standard"}
            value={forms.endDate}
            onChange={(e) => setForms({ ...forms, endDate: e.target.value })}
          />
        </div>
        <div className="filter_field">
          <Dropdown
            label="Owner"
            // options={owner}
            options={[{ name: "All", value: null }, ...owner]}
            value={forms.owner ? forms.owner : ""}
            onChange={(e) => setForms({ ...forms, owner: e.target.value })}
          />
        </div>
        {/* <div className="filter_field_date">
          <DobPicker label="Due Date" inputVariant={"standard"} value="" />
        </div> */}
        <div className="filter_field_btn filter_btn">
          <Button
            variant="contained"
            color="primary"
            onClick={() => props.setFilter(forms)}
          >
            Filter
          </Button>
        </div>
      </div>
    </div>
  );
}

const mapStateToProps = (state) => ({
  demographicsData: state.demographics,
});

const mapDispatchToProps = (dispatch) => ({
  getCache: (values) => dispatch(getCache(values)),
  setFilter: (values) => dispatch(setFilter(values)),
});
DataTableFilter.propTypes = {
  getCache: PropTypes.func,
  setFilter: PropTypes.func,
};
export default connect(mapStateToProps, mapDispatchToProps)(DataTableFilter);
