import {
  CACHE,
  CACHE_RES,
  DEMOGRAPHICS_LIST,
  DEMOGRAPHICS_LIST_RES,
  LOGIN,
  LOGIN_INFO,
  LOGIN_INFO_RES,
  LOGIN_RES,
  SET_FILTER,
  GET_PATIENT_DETAILS,
  GET_PATIENT_DETAILS_RES,
  SAVE_PATIENT_DETAILS_RES,
  SAVE_PATIENT_DETAILS,
  INSURANCE_LIST,
  INSURANCE_LIST_RES,
  PATIENT_NOTES,
  PATIENT_NOTES_RES,
  SAVE_NOTES_BY_PATIENT_ID,
  SAVE_NOTES_PATIENT_ID_RES,
  SEARCH_PAYER,
  SEARCH_PAYER_RES,
  INSURANCE_SAVE,
  INSURANCE_SAVE_RES,
  CLAIM_HISTORY,
  CLAIM_HISTORY_RES,
  FILE_LIST,
  FILE_LIST_RES,
  FILE_DOWNLOAD,
  FILE_DOWNLOAD_RES,
  FILE_UPLOAD,
  FILE_UPLOAD_RES,
  UPDATE_CLAIM_STATUS,
  UPDATE_CLAIM_STATUS_RES,
  FACILITY_LIST,
  PROVIDER_LIST_RES,
  PROVIDER_LIST,
  FACILITY_LIST_RES,
  PAYOR_ADDRESS_LIST,
  PAYOR_ADDRESS_LIST_RES,
  POLICY_HOLDER_SAVE,
  POLICY_HOLDER_SAVE_RES,
  CREATE_INSURANCE_ADDRESS,
  CREATE_INSURANCE_ADDRESS_RES,
  SEARCH_PATIENT,
  SEARCH_PATIENT_RES,
  SEARCH_FILTER,
} from "./demographics.types";

export const login = (data) => ({
  type: LOGIN,
  payload: data,
});

export const loginResponse = (res) => ({
  type: LOGIN_RES,
  payload: res,
});

export const demographicsList = (data) => ({
  type: DEMOGRAPHICS_LIST,
  payload: data,
});

export const demographicsListResponse = (res) => ({
  type: DEMOGRAPHICS_LIST_RES,
  payload: res,
});

export const loginInfo = (data) => ({
  type: LOGIN_INFO,
  payload: data,
});

export const loginInfoResponse = (data) => ({
  type: LOGIN_INFO_RES,
  payload: data,
});

export const searchPatient = (data) => ({
  type: SEARCH_PATIENT,
  payload: data,
});
export const searchPatientResponse = (res) => ({
  type: SEARCH_PATIENT_RES,
  payload: res,
});
export const setFilter = (data) => ({
  type: SET_FILTER,
  payload: data,
});
export const setSearchFilter = (data) => ({
  type: SEARCH_FILTER,
  payload: data,
});

export const getCache = (res) => ({
  type: CACHE,
  payload: res,
});

export const getCacheResponse = (res) => ({
  type: CACHE_RES,
  payload: res,
});

export const patientDetails = (data) => ({
  type: GET_PATIENT_DETAILS,
  payload: data,
});

export const patientDetailsResponse = (res) => ({
  type: GET_PATIENT_DETAILS_RES,
  payload: res,
});

export const savePatientDetails = (data) => ({
  type: SAVE_PATIENT_DETAILS,
  payload: data,
});

export const savePatientDetailsResponse = (res) => ({
  type: SAVE_PATIENT_DETAILS_RES,
  payload: res,
});

export const insuranceList = (data) => ({
  type: INSURANCE_LIST,
  payload: data,
});

export const insuranceListResposne = (res) => ({
  type: INSURANCE_LIST_RES,
  payload: res,
});

export const patientNotes = (data) => ({
  type: PATIENT_NOTES,
  payload: data,
});

export const patientNotesResponse = (res) => ({
  type: PATIENT_NOTES_RES,
  payload: res,
});
export const saveNotesByPatientId = (value) => ({
  type: SAVE_NOTES_BY_PATIENT_ID,
  payload: value,
});

export const saveNotesByPatientIdRes = (value) => ({
  type: SAVE_NOTES_PATIENT_ID_RES,
  payload: value,
});
export const searchPayer = (data) => ({
  type: SEARCH_PAYER,
  payload: data,
});

export const searchPayerResponse = (res) => ({
  type: SEARCH_PAYER_RES,
  payload: res,
});

export const insuranceSave = (data) => ({
  type: INSURANCE_SAVE,
  payload: data,
});

export const insuranceSaveResponse = (res) => ({
  type: INSURANCE_SAVE_RES,
  payload: res,
});

export const claimHistory = (data) => ({
  type: CLAIM_HISTORY,
  payload: data,
});

export const claimHistoryResponse = (res) => ({
  type: CLAIM_HISTORY_RES,
  payload: res,
});

export const fileList = (data) => ({
  type: FILE_LIST,
  payload: data,
});

export const fileListResponse = (res) => ({
  type: FILE_LIST_RES,
  payload: res,
});

export const fileDownload = (data) => ({
  type: FILE_DOWNLOAD,
  payload: data,
});

export const fileDownloadResponse = (res) => ({
  type: FILE_DOWNLOAD_RES,
  payload: res,
});

export const fileUpload = (data) => ({
  type: FILE_UPLOAD,
  payload: data,
});

export const fileUploadResponse = (res) => ({
  type: FILE_UPLOAD_RES,
  payload: res,
});

export const updateClaimStatus = (data) => ({
  type: UPDATE_CLAIM_STATUS,
  payload: data,
});

export const updateClaimStatusResponse = (res) => ({
  type: UPDATE_CLAIM_STATUS_RES,
  payload: res,
});

export const facilityList = (data) => ({
  type: FACILITY_LIST,
  payload: data,
});

export const facilityListResponse = (res) => ({
  type: FACILITY_LIST_RES,
  payload: res,
});

export const providerList = (data) => ({
  type: PROVIDER_LIST,
  payload: data,
});

export const providerListResponse = (res) => ({
  type: PROVIDER_LIST_RES,
  payload: res,
});

export const payorAddressList = (data) => ({
  type: PAYOR_ADDRESS_LIST,
  payload: data,
});

export const payorAddressListResponse = (res) => ({
  type: PAYOR_ADDRESS_LIST_RES,
  payload: res,
});

export const policyHolderSave = (data) => ({
  type: POLICY_HOLDER_SAVE,
  payload: data,
});

export const policyHolderSaveResponse = (res) => ({
  type: POLICY_HOLDER_SAVE_RES,
  payload: res,
});

export const createInsuranceAddress = (data) => ({
  type: CREATE_INSURANCE_ADDRESS,
  payload: data,
});

export const createInsuranceAddressResponse = (res) => ({
  type: CREATE_INSURANCE_ADDRESS_RES,
  payload: res,
});
