import React, { useRef, useState } from "react";
import { Link } from "react-router-dom";
import { Button, Popover, makeStyles } from "@material-ui/core";
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos";
import NavigateNextIcon from "@material-ui/icons/NavigateNext";
import "./style.css";
import { SearchPatient } from "../PatientSearch";

const useStyles = makeStyles((theme) => ({
  icon: {
    fontSize: "2rem !important",
  },
  popover: {
    pointerEvents: "none",
  },
  popoverContent: {
    pointerEvents: "auto",
  }, 
  root: {
    flexGrow: 1,
  },
  search: {
    marginTop: "15px",
    border: "1px solid grey",
    position: "relative",
    borderRadius: "50px",
    backgroundColor: "white",
    "&:hover": {
      backgroundColor: "white",
    },
    marginLeft: 0,
    width: "100%",
    [theme.breakpoints.up("sm")]: {
      marginLeft: theme.spacing(1),
      width: "auto",
    },
  },
  searchIcon: {
    padding: theme.spacing(0, 2),
    height: "100%",
    position: "absolute",
    pointerEvents: "none",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  inputRoot: {
    color: "inherit",
  },
  inputInput: {
    color: "black",
    padding: theme.spacing(1, 1, 1, 0),
    // vertical padding + font size from searchIcon
    paddingLeft: `calc(1em + ${theme.spacing(4)}px)`,
    transition: theme.transitions.create("width"),
    width: "100%",
    [theme.breakpoints.up("sm")]: {
      width: "12ch",
      "&:focus": {
        width: "25ch",
      },
    },
  },
}));

function Header(props) {
  // const rowData = JSON.parse(localStorage.getItem("RowData"));
  const classes = useStyles();
  const [openedPopover, setOpenedPopover] = useState(false);

  const popoverAnchor = useRef(null);

  const popoverEnter = () => {
    setOpenedPopover(true);
  };

  const popoverLeave = () => {
    setOpenedPopover(false);
  };

  const logOutUser = () => {
    localStorage.clear();
    window.location.href = "/login";
  };

  const handleBackButton = () => {
    sessionStorage.removeItem("demographicsData");
  };

  //when the user navigates back
  window.onpopstate = function (event) {
    sessionStorage.removeItem("demographicsData");
  };

  return (
    <div className="clinic__header">
      <div className="clinic__header--inner header-left">
        {props.backButton && (
          <Link to="/home">
            <ArrowBackIcon
              // onClick={() => props.visible(false)}
              onClick={handleBackButton}
              style={{
                marginLeft: "50px",
                fontSize: "1.5rem",
                cursor: "pointer",
                color: "grey",
              }}
            />
          </Link>
        )}
        {props.prevButton && (
          <Button
            onClick={() => props.handlePrev()}
            variant="outlined"
            color="primary"
            style={{ marginLeft: "50px", marginRight: "50px" }}
            disabled={props.disablePrev}
          >
            <ArrowBackIosIcon style={{ fontSize: "1rem" }} />
            Previous
          </Button>
        )}
        <div>
          <ul>
            <li
            // style={{ margin: "-100px" }}
            >
              {props.name ? "" : "Demographics"}
            </li>
          </ul>
        </div>
        <div className="edit-screen">
          <ul>
            <li>{props.name ? props.name : ""}</li>
          </ul>
          <span>{props.dos}</span>
          <span>{props.patientName}</span>
          {/* <span style={{ marginLeft: "20px" }}>{props.type}</span> */}

          <span>{props.dob}</span>
        </div>
      </div>

      <div className="clinic__header--inner header-right">
        <div className="next-btn">
          {props.nextButton && (
            <Button
              onClick={() => props.handleNext()}
              variant="outlined"
              color="primary"
              style={{ marginRight: "150px" }}
              disabled={props.disableNext}
            >
              Next
              <NavigateNextIcon style={{ fontSize: "1.5rem" }} />
            </Button>
          )}
        </div>
        {!props.backButton && (
          <div>
            <SearchPatient />
          </div>
        )}
        {/* <div>
          {props.backButton === false && (
            <div className={classes.root}>
              <Toolbar style={{ border: "none" }}>
                <div className={classes.search}>
                  <div className={classes.searchIcon}>
                    <SearchIcon />
                  </div>
                  <div>
                    <Autocomplete
                      // options={search}
                      // onChange={(e, y) => searchSelect(e, y)}
                      getOptionLabel={(option) => option.title}
                      style={{ width: 350 }}
                      renderInput={(params) => {
                        const { InputLabelProps, InputProps, ...rest } = params;
                        return (
                          <InputBase
                            placeholder="Search…"
                            // onChange={searchPatient}
                            classes={{
                              root: classes.inputRoot,
                              input: classes.inputInput,
                            }}
                            {...params.InputProps}
                            {...rest}
                          />
                        );
                      }}
                    />
                  </div>
                </div>
              </Toolbar>
            </div>
          )}
        </div> */}
        {/* {props.name ? (
          ""
        ) : (
          <div className="filter_container">
            <div className="filterColumn_icon">
              <IconButton
                id="column-filter"
                // aria-controls={openFilter ? "column-menu" : undefined}
                // aria-expanded={openFilter ? "true" : undefined}
                aria-haspopup="true"
                // onClick={handleFilterColumn}
              >
                <FilterIcon />
              </IconButton>
            </div>
            <Menu
              className="columnMenu"
              id="column-menu"
              MenuListProps={{
                "aria-labelledby": "column-filter",
              }}
              // anchorEl={anchorEl}
              // open={openFilter}
              // onClose={handleFilterColumnClose}
              PaperProps={{
                style: {
                  width: "55ch",
                },
              }}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "center",
              }}
              transformOrigin={{
                vertical: "bottom",
                horizontal: "center",
              }}
            >
              <FormGroup className="column_filter_container">
                <Box sx={{ flexGrow: 1 }}>
                  <Grid container spacing={0}>
                    {headCells.map((x, i) => (
                      <Grid item xs={4} key={i}>
                        <FormControlLabel
                          className="column_filter_text"
                          control={<Checkbox size="small" color="primary" />}
                          onChange={(e) => handleFilterCheckbox(e)}
                          name={x.id}
                          label={x.label}
                          checked={x.status}
                          labelPlacement="end"
                        />
                      </Grid>
                    ))}
                  </Grid>
                </Box>
              </FormGroup>
            </Menu>
          </div>
        )} */}
        <div
          className="clinic__profileCircle"
          ref={popoverAnchor}
          aria-owns="mouse-over-popover"
          aria-haspopup="true"
          onMouseEnter={popoverEnter}
          onMouseLeave={popoverLeave}
        >
          <span>D</span>
        </div>
        <KeyboardArrowDownIcon color="primary" />
      </div>
      <Popover
        id="mouse-over-popover"
        className={classes.popover}
        classes={{
          paper: classes.popoverContent,
        }}
        open={openedPopover}
        anchorEl={popoverAnchor.current}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
        PaperProps={{ onMouseEnter: popoverEnter, onMouseLeave: popoverLeave }}
      >
        <div className="clinicHeader__popover">
          <div className="clinicHeader__popover--name">Demographics</div>
          <div className="clinicHeader__popover--list">
            <div
              className="logout"
              onClick={() => logOutUser()}
              style={{ textDecoration: "underline", color: "#139696" }}
            >
              <span>Logout</span>
            </div>
          </div>
        </div>
      </Popover>
    </div>
  );
}

export default Header;
