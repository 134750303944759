import {
  CACHE_RES,
  DEMOGRAPHICS_LIST_RES,
  LOGIN_INFO_RES,
  LOGIN_RES,
  SET_FILTER,
  GET_PATIENT_DETAILS_RES,
  SAVE_PATIENT_DETAILS_RES,
  INSURANCE_LIST_RES,
  PATIENT_NOTES_RES,
  SAVE_NOTES_PATIENT_ID_RES,
  SEARCH_PAYER_RES,
  INSURANCE_SAVE_RES,
  CLAIM_HISTORY_RES,
  FILE_LIST_RES,
  FILE_DOWNLOAD_RES,
  FILE_UPLOAD_RES,
  UPDATE_CLAIM_STATUS_RES,
  FACILITY_LIST_RES,
  PROVIDER_LIST_RES,
  PAYOR_ADDRESS_LIST_RES,
  POLICY_HOLDER_SAVE_RES,
  CREATE_INSURANCE_ADDRESS_RES,
  SEARCH_PATIENT_RES,
  SEARCH_FILTER,
} from "./demographics.types";

const currentDate = new Date();
const startOfMonth = new Date(
  currentDate.getFullYear(),
  currentDate.getMonth(),
  1
);

const INITIAL_STATE = {
  loginResponse: null,
  tableData: null,
  loginInfo: null,
  filterForm: {
    clinic: null,
    provider: null,
    ptStatus: null,
    insStatus: null,
    owner: null,
    startDate: startOfMonth,
    endDate: currentDate,
    patientId: null,
  },
  searchFilter: null,
  cache: null,
  patientDetails: null,
  savePatientDetails: null,
  insuranceList: {},
  patientNotes: {},
  savePatientNotesRes: {},
  searchPayer: null,
  insuranceSave: null,
  claimHistory: null,
  fileList: null,
  fileDownload: null,
  fileUpload: null,
  updateClaimStatus: null,
  facilityList: null,
  providerList: null,
  payorAddressList: null,
  policyHolderSave: null,
  createInsuranceAddress: null,
  searchPatients: null,
};

const demographicsReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case LOGIN_RES: {
      return { ...state, loginResponse: action.payload };
    }
    case DEMOGRAPHICS_LIST_RES: {
      return { ...state, tableData: action.payload };
    }
    case LOGIN_INFO_RES: {
      return { ...state, ...{ loginInfo: action.payload } };
    }
    case SET_FILTER: {
      return { ...state, ...{ filterForm: action.payload } };
    }
    case SEARCH_FILTER: {
      return { ...state, ...{ searchFilter: action.payload } };
    }
    case SEARCH_PATIENT_RES: {
      return { ...state, ...{ searchPatients: action.payload } };
    }
    case CACHE_RES: {
      return { ...state, ...{ cache: action.payload } };
    }
    case GET_PATIENT_DETAILS_RES: {
      return { ...state, ...{ patientDetails: action.payload } };
    }
    case SAVE_PATIENT_DETAILS_RES: {
      return { ...state, ...{ savePatientDetails: action.payload } };
    }
    case INSURANCE_LIST_RES: {
      return { ...state, ...{ insuranceList: action.payload } };
    }
    case PATIENT_NOTES_RES: {
      return { ...state, ...{ patientNotes: action.payload } };
    }
    case SAVE_NOTES_PATIENT_ID_RES: {
      return {
        ...state,
        ...{ savePatientNotesRes: action.payload },
      };
    }
    case SEARCH_PAYER_RES: {
      return { ...state, ...{ searchPayer: action.payload } };
    }
    case INSURANCE_SAVE_RES: {
      return { ...state, ...{ insuranceSave: action.payload } };
    }
    case CLAIM_HISTORY_RES: {
      return { ...state, ...{ claimHistory: action.payload } };
    }
    case FILE_LIST_RES: {
      return { ...state, ...{ fileList: action.payload } };
    }
    case FILE_DOWNLOAD_RES: {
      return { ...state, ...{ fileDownload: action.payload } };
    }
    case FILE_UPLOAD_RES: {
      return { ...state, ...{ fileUpload: action.payload } };
    }
    case UPDATE_CLAIM_STATUS_RES: {
      return { ...state, ...{ updateClaimStatus: action.payload } };
    }
    case FACILITY_LIST_RES: {
      return { ...state, ...{ facilityList: action.payload } };
    }
    case PROVIDER_LIST_RES: {
      return { ...state, ...{ providerList: action.payload } };
    }
    case PAYOR_ADDRESS_LIST_RES: {
      return { ...state, ...{ payorAddressList: action.payload } };
    }
    case POLICY_HOLDER_SAVE_RES: {
      return { ...state, ...{ policyHolderSave: action.payload } };
    }
    case CREATE_INSURANCE_ADDRESS_RES: {
      return { ...state, ...{ createInsuranceAddress: action.payload } };
    }
    default:
      return state;
  }
};

export default demographicsReducer;
