import { combineEpics } from "redux-observable";
import DemographicsEpic from "./Demographics/demographics.epic";

const rootEpic = combineEpics(
  DemographicsEpic.login,
  DemographicsEpic.demographicsList,
  DemographicsEpic.loginInfo,
  DemographicsEpic.cacheApi,
  DemographicsEpic.getPatientDetails,
  DemographicsEpic.savePatientDetails,
  DemographicsEpic.getPolicyByPatientId,
  DemographicsEpic.PatientNotes,
  DemographicsEpic.savePatientNotes,
  DemographicsEpic.searchPayer,
  DemographicsEpic.insuranceSave,
  DemographicsEpic.claimHistory,
  DemographicsEpic.fileList,
  DemographicsEpic.fileDownload,
  DemographicsEpic.FileUpload,
  DemographicsEpic.updateClaimStatus,
  DemographicsEpic.facilityList,
  DemographicsEpic.providerList,
  DemographicsEpic.payorAddressList,
  DemographicsEpic.policyHolderSave,
  DemographicsEpic.createInsuranceAddress,
  DemographicsEpic.searchPatientApi
);
export default rootEpic;
