import React, { useEffect, useState } from "react";
import {
  Button,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@material-ui/core";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import {
  insuranceList,
  insuranceSave,
} from "../../Redux/Demographics/demographics.actions";
import NoDataFound from "../../assets/nodata-found.png";
import { Skeleton } from "@material-ui/lab";
import Insurance from "../Insurance";
import EditIcon from "@material-ui/icons/Edit";
import Dropdown from "../Dropdown";
import "./style.css";

function InsuranceList(props) {
  const [loading, setLoading] = useState(true);
  const [policyList, setPolicyList] = useState([]);
  const [openInsuarnce, setOpenInsuarnce] = useState(false);
  const [insuranceDetails, setInsuranceDetails] = useState(null);
  const [insuranceStatusUpdate, setInsuranceStatusUpdate] = useState(null);

  const InsuranceStatus = [
    { name: "Active", value: 1 },
    { name: "Inactive", value: 0 },
    { name: "Verify", value: 2 },
    { name: "Pending", value: 3 },
  ];

  useEffect(() => {
    // let id = window.location.href.split("/").pop();
    props.insuranceList(props.rowData.id);
    // console.log("rowdata id from insurance list", props.rowData.id);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.rowData.id]); // added id in dependency for update evrey  time when the id change 

  useEffect(() => {
    if (
      props.demographicsData.insuranceList &&
      props.demographicsData.insuranceList.response
    ) {
      let response = props.demographicsData.insuranceList.response;
      if (response.responseCode === 0) {
        let data = response.data.policyDtoList;
        setPolicyList(data);
        setLoading(false);
      }
    }
  }, [props.demographicsData.insuranceList]);

  const sentenceCase = (txt) => {
    if (!txt) return "";
    return txt
      .toLowerCase()
      .split(" ")
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
      .join(" ");
  };

  const handleInsurance = (x) => {
    setInsuranceDetails(x);
    setOpenInsuarnce(true);
  };

  const handleChange = (e, x, i) => {
    const policy = policyList;
    policy[i].active = e.target.value;
    setPolicyList([...policy]);
    props.insuranceSave({
      ...x,
      active: e.target.value,
    });
    setInsuranceStatusUpdate(x.id);
  };

  function getDisplayName(policyHolderName) {
    const [firstName, ...lastName] = policyHolderName.split(" ");
    const lastNameInitial = lastName.length > 0 ? lastName[0].charAt(0) : "";
    const displayName = `${firstName} ${lastNameInitial}`;
    return sentenceCase(displayName);
  }

  const handleCloseInsurance = () => {
    setOpenInsuarnce(false);
    setInsuranceDetails(null);
  };
  // console.log("check policylist array::::", policyList);
  return (
    <div className="insurance_list_container">
      <Insurance
        rowData={props.rowData}
        open={openInsuarnce}
        handleClose={() => handleCloseInsurance()}
        insurance={insuranceDetails}
        insuranceStatus={insuranceStatusUpdate}
        setInsuranceStatus={setInsuranceStatusUpdate}
      />
      <div className="insurance_heading">
        Insurance List{" "}
        <div>
          <Button
            variant="contained"
            color="primary"
            onClick={() => setOpenInsuarnce(true)}
            style={{ borderRadius: "10px" }}
          >
            Add
          </Button>
        </div>
      </div>
      {loading ? (
        <TableContainer
          style={{ border: "1px solid #dbdbdb", borderRadius: "10px" }}
        >
          <Table>
            <TableHead style={{ backgroundColor: "#f2fbf9" }}>
              <TableRow>
                {[...Array(7)].map((_, i) => (
                  <TableCell key={i}>
                    <Skeleton />
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {[...Array(2)].map((_, i) => (
                <TableRow key={i}>
                  {[...Array(7)].map((_, j) => (
                    <TableCell key={j}>
                      <Skeleton />
                    </TableCell>
                  ))}
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      ) : policyList.length > 0 ? (
        <TableContainer
          style={{ border: "1px solid #dbdbdb", borderRadius: "10px" }}
        >
          <Table>
            <TableHead style={{ backgroundColor: "#f2fbf9" }}>
              <TableRow>
                <TableCell className="policyList_tabelCell"></TableCell>
                <TableCell className="policyList_tabelCell">Name</TableCell>
                <TableCell className="policyList_tabelCell">Policy#</TableCell>
                <TableCell className="policyList_tabelCell">Group#</TableCell>
                <TableCell className="policyList_tabelCell">Holder</TableCell>
                <TableCell className="policyList_tabelCell">Status</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {policyList.map((x, i) => (
                <TableRow>
                  <TableCell
                    style={{ textAlign: "center", padding: "5px 10px" }}
                  >
                    {x.ranking}
                  </TableCell>
                  <TableCell className="policyList_tabelCell">
                    {sentenceCase(x.payerName.substring(0, 15))}
                  </TableCell>
                  <TableCell className="policyList_tabelCell">
                    {x.policyNo}
                  </TableCell>
                  <TableCell className="policyList_tabelCell">
                    {x.groupNo}
                  </TableCell>
                  <TableCell className="policyList_tabelCell">
                    {getDisplayName(x.policyHolderName)}
                  </TableCell>
                  <TableCell className="policyList_tabelCell">
                    <div style={{ display: "flex" }}>
                      <div style={{ marginTop: "-15px", width: "85px" }}>
                        <Dropdown
                          options={InsuranceStatus}
                          value={x.active}
                          onChange={(e) => handleChange(e, x, i)}
                        />
                      </div>
                      <IconButton style={{ padding: "0 3px" }}>
                        <EditIcon
                          style={{
                            color: "grey",
                            cursor: "pointer",
                          }}
                          onClick={() => handleInsurance(x)}
                        />
                      </IconButton>
                    </div>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      ) : (
        <div>
          <img
            style={{ height: "200px", width: "100%", padding: "0 7rem" }}
            src={NoDataFound}
            alt=""
          />
        </div>
      )}
    </div>
  );
}

const mapStateToProps = (state) => ({
  demographicsData: state.demographics,
});

const mapDispatchToProps = (dispatch) => ({
  insuranceList: (values) => dispatch(insuranceList(values)),
  insuranceSave: (values) => dispatch(insuranceSave(values)),
  // payorAddress: (values) => dispatch(payorAddressList(values)),
});
InsuranceList.propTypes = {
  insuranceList: PropTypes.func,
  insuranceSave: PropTypes.func,
  // payorAddress: PropTypes.func,
};
export default connect(mapStateToProps, mapDispatchToProps)(InsuranceList);
