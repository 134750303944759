import React, { useEffect, useState } from "react";
import AppLogout from "../../Utils/inactive";
import PatientSelector from "../../Components/PatientSelector";
import Header from "../../Components/Header";
import Demographics from "../../Components/Demographics";
import InsuranceList from "../../Components/InsuranceList";
import Notes from "../../Components/Notes";
import { Button } from "@material-ui/core";
import FileUploader from "../../Components/FileUploader";
import ClaimHistory from "../../Components/ClaimHistory";
import { claimHistory } from "../../Redux/Demographics/demographics.actions";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import "./style.css";
import UploadList from "../../Components/uploadList";

function EditContainer(props) {
  const [rowData, setRowData] = useState({});
  const patientData = JSON.parse(localStorage.getItem("RowData")) ;
  const [openTab, setOpenTab] = useState("files");
  const [open, setOpen] = React.useState(false);

  const handleOpen = () => {
    setOpen(true);
    props.claimHistory({
      iclinicId: "0",
      btReportType: 1,
      sstartDate: "",
      sendDate: "",
      iproviderId: 0,
      payorId: 0,
      blLedgerDos: true,
      btexportType: 1,
      btrptType: 1,
      status: "0",
      facilityId: "0",
      serviceId: "0",
      sproviderIds: "0",
      offset: 0,
      patientId: props.currentRow.id,
    });
  };
  useEffect(() => {
    if (props.demographicsData.patientDetails) {
      let response = props.demographicsData.patientDetails.response;
      if (response.responseCode === 0) {
        setRowData(response.data);
      }
    }
  }, [props.demographicsData.patientDetails]);
  return (
    <AppLogout>
      <div className="wrapper">
        <ClaimHistory
          currentRow={props.currentRow}
          open={open}
          handleClose={() => setOpen(false)}
        />
        <div className="leftTabOpen">
          <PatientSelector
            currentRow={props.currentRow}
            setCurrentRow={props.setCurrentRow}
            patients={props.patientList}
          />
        </div>
        <div className="container_max">
          <div className="headerComponent">
            <Header
              name={"Edit Demographics"}
              backButton={true}
              visible={(e) => props.visible(e)}
              dos={patientData?.dos}
              patientName={
                rowData.lastName || rowData.firstName
                  ? " | " + rowData.lastName + " " + rowData.firstName + " | "
                  : ""
              }
              dob={rowData.sdob ? rowData.sdob : ""}
            />
          </div>
          <div className="mainContainer">
            <div className="main_edit_section">
              <div className="main_edit_section_button">
                <Button
                  className="button_active"
                  variant="contained"
                  color="primary"
                  onClick={handleOpen}
                >
                  Claim History
                </Button>
              </div>
              <div className="demographics_container">
                <Demographics rowData={props.currentRow} />
              </div>
              <div className="demographics_container">
                <InsuranceList rowData={props.currentRow} />{" "}
              </div>{" "}
              {/* <div className="demographics_container">
                <FileUploader />{" "}
              </div> */}
            </div>

            <div className="right_tab_section">
              <div className="button_container">
                <div className="button_group">
                  {/* <Button
                    className={
                      openTab === "insurance" ? "tabButtons" : "button_active"
                    }
                    variant="contained"
                    color="primary"
                    disableElevation
                    size="large"
                    onClick={() => setOpenTab("insurance")}
                  >
                    Insurance List
                  </Button> */}

                  <Button
                    className={
                      openTab === "files" ? "tabButtons" : "button_active"
                    }
                    variant="contained"
                    color="primary"
                    disableElevation
                    size="large"
                    onClick={() => setOpenTab("files")}
                  >
                    Files
                  </Button>
                  <Button
                    className={
                      openTab === "notes" ? "tabButtons" : "button_active"
                    }
                    variant="contained"
                    color="primary"
                    disableElevation
                    size="large"
                    onClick={() => setOpenTab("notes")}
                  >
                    Notes
                  </Button>
                </div>
              </div>
              {/* {openTab === "insurance" && (
                <div className="demographics_container">
                  <InsuranceList />
                </div>
              )} */}
              {openTab === "notes" && (
                <div className="demographics_container">
                  <Notes rowData={props.currentRow} />
                </div>
              )}
              {openTab === "files" && (
                <div className="demographics_container">
                  <UploadList rowData={props.currentRow} />
                  <FileUploader rowData={props.currentRow} />
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </AppLogout>
  );
}

const mapStateToProps = (state) => ({
  demographicsData: state.demographics,
});

const mapDispatchToProps = (dispatch) => ({
  claimHistory: (values) => dispatch(claimHistory(values)),
});
EditContainer.propTypes = {
  claimHistory: PropTypes.func,
};

export default connect(mapStateToProps, mapDispatchToProps)(EditContainer);
