import React, { useEffect, useState } from "react";
import {
  Divider,
  IconButton,
  Tab,
  Tabs,
  Tooltip,
  withStyles,
} from "@material-ui/core";
import MenuIcon from "@material-ui/icons/Menu";
import { Pagination } from "@material-ui/lab";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import {
  demographicsList,
  fileList,
  insuranceList,
  patientDetails,
} from "../../Redux/Demographics/demographics.actions";
import "./style.css";

// Custom styled Tab component
const StyledTabs = withStyles({
  indicator: {
    display: "none",
    width: "0",
    justifyContent: "center",
    backgroundColor: "transparent",
    "& > span": {
      // maxWidth: 600,
      width: "100%",
      fontWeight: "bolder",
      backgroundColor: "black",
    },
  },
  root: {
    borderTop: "none",
    borderTopRightRadius: "0px",
    borderTopLeftRadius: "0px",
    paddingLeft: "10px",
  },
})((props) => <Tabs {...props} TabIndicatorProps={{ children: <span /> }} />);

const StyledTab = withStyles((theme) => ({
  root: {
    textTransform: "none",
    // height: '75px',
    marginBottom: "0px",
    borderBottom: "1px solid lightGray",
    fontWeight: theme.typography.fontWeightRegular,
    // fontSize: theme.typography.pxToRem(15),
    marginRight: theme.spacing(1),
    "&:focus": {
      opacity: 1,
      // backgroundColor: '#dff5f0',
      border: "none",
      // borderRadius: '10px',
    },
  },
}))((props) => <Tab disableRipple {...props} />);

function a11yProps(index) {
  return {
    id: `vertical-tab-${index}`,
    "aria-controls": `vertical-tabpanel-${index}`,
  };
}

function PatientSelector(props) {
  const [drawerOpen, setDrawerOpen] = useState(false); // State to manage drawer open/close
  const [patientList, setPatientList] = useState([]); // State to manage the displayed patients
  const [value, setValue] = useState(0); // State to manage the selected tab
  const [count, setCount] = useState(0); // State to manage the total page count
  const [page, setPage] = useState(1); // State to manage the current page
  const itemsPerPage = 10; // Number of items per page

  useEffect(() => {
    // console.log(props.patients);
  }, [props.patients]);
  // Handle drawer open/close
  const handleClick = (type) => {
    if (type === "close") {
      setDrawerOpen(true);
    } else {
      setDrawerOpen(false);
    }
  };
  // Function to update patientList based on the current page
  const updatePatientList = (currentPage) => {
    const startIndex = (currentPage - 1) * itemsPerPage;
    const endIndex = startIndex + itemsPerPage;
    setPatientList(props.patients.slice(startIndex, endIndex));
  };
  // Initialize patientList and count based on props.patients
  useEffect(() => {
    if (props.patients.length > 0) {
      const rowData = JSON.parse(localStorage.getItem("RowData"));
      const currentPatientIndex = props.patients.findIndex(
        (patient) => patient.id === rowData.id
      );
      const currentPage = Math.floor(currentPatientIndex / itemsPerPage) + 1;
      setPage(currentPage);
      setPatientList(
        props.patients.slice(
          (currentPage - 1) * itemsPerPage,
          currentPage * itemsPerPage
        )
      );
      setValue(currentPatientIndex % itemsPerPage);
      const totalPages = Math.ceil(props.patients.length / itemsPerPage);
      setCount(totalPages <= 1 ? 1 : totalPages);
    }
  }, [props.patients]);
  // Update patientList when page or props.patients changes
  useEffect(() => {
    updatePatientList(page);
  }, [page, props.patients]);

  // Handle page change in pagination
  const handlePaginationChange = (event, newPage) => {
    setPage(newPage);
    setValue(0);
  };
  // Handle tab change
  const handleChange = (event, newValue) => {
    setValue(newValue);
    const overallIndex = (page - 1) * itemsPerPage + newValue;
    const newData = props.patients[overallIndex];
    localStorage.setItem("RowData", JSON.stringify(newData));
    props.setCurrentRow(newData);
    props.getPatientDetails(newData.id);
    props.insuranceList(newData.id);
    props.fileList({
      patientId: newData.id,
    });
  };
  // Check if the patient is the currently selected one
  const checkArray = (patient) => {
    const rowData = JSON.parse(localStorage.getItem("RowData"));
    return rowData.id === patient.id;
  };
  // Convert text to sentence case
  const sentanceCase = (txt) => {
    return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
  };

  return (
    <>
      {drawerOpen ? (
        <div className="close_left_tab">
          <IconButton
            onClick={() => handleClick("open")}
            style={{ marginTop: "0.6rem" }}
          >
            <MenuIcon />
          </IconButton>
        </div>
      ) : (
        <div className="open">
          <div className="patient_selector_heading_container">
            <IconButton onClick={() => handleClick("close")}>
              <MenuIcon />
            </IconButton>
            <div className="left_tab_pagination">
              <Pagination
                className="pag"
                siblingCount={0}
                shape="round"
                size="small"
                color="primary"
                count={count}
                page={page}
                onChange={handlePaginationChange}
                boundaryCount={1}
              />
            </div>
          </div>
          <Divider variant="middle" />
          <div className="dropdown_header">
            <span className="dropdown_title">My Open Items</span>
          </div>
          <Divider variant="middle" />

          <StyledTabs
            orientation="vertical"
            variant="scrollable"
            scrollButtons="off"
            value={value}
            onChange={handleChange}
            aria-label="Vertical tabs"
            className={"tab_container"}
          >
            {patientList.map((x, i) => (
              <StyledTab
                key={x.id}
                label={
                  <div
                    className={checkArray(x) === true ? "tabRow" : "active_tab"}
                  >
                    <div className="firstRow">
                      <span style={{ width: "90px" }}>{x.dos}</span>
                      <Tooltip
                        title={`${sentanceCase(x.lastName)} ${sentanceCase(
                          x.firstName
                        )}`}
                      >
                        <span>
                          {`${sentanceCase(x.lastName)} ${sentanceCase(
                            x.firstName.substring(0, 1)
                          )}`.substring(0,11)}
                        </span>
                      </Tooltip>
                    </div>
                    <div className="secondRow">
                      {/* <span style={{ width: "85px" }}>{x.result}</span> */}
                        <Tooltip title={sentanceCase(x.payorName)}>
                      <span>

                        {x.payorName && x.payorName.length > 13
                          ? `${sentanceCase(x.payorName).substring(0, 16)}...`
                          : x.payorName}
                      </span>
                          </Tooltip>  
                    </div>
                  </div>
                }
                {...a11yProps(i + 1)}
              />
            ))}
          </StyledTabs>
        </div>
      )}
    </>
  );
}
// Map Redux state to component props
const mapStateToProps = (state) => ({
  demographicsData: state.demographics,
});
// Map Redux actions to component props
const mapDispatchToProps = (dispatch) => ({
  demographicsList: (values) => dispatch(demographicsList(values)),
  getPatientDetails: (values) => dispatch(patientDetails(values)),
  insuranceList: (values) => dispatch(insuranceList(values)),
  fileList: (values) => dispatch(fileList(values)),
});
// PropTypes for typechecking
PatientSelector.propTypes = {
  demographicsList: PropTypes.func,
  getPatientDetails: PropTypes.func,
  insuranceList: PropTypes.func,
  fileList: PropTypes.func,
};

export default connect(mapStateToProps, mapDispatchToProps)(PatientSelector);
