import React, { useEffect, useState } from "react";
import {
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  Grid,
} from "@material-ui/core";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import Input from "../Input";
import DobPicker from "../dobPicker";
import Dropdown from "../Dropdown";
import PhoneFormat from "../PhoneFormat";
import states from "../../HelperFunctions/states.json";
import "./style.css";
import {
  insuranceList,
  policyHolderSave,
} from "../../Redux/Demographics/demographics.actions";
import toast from "react-hot-toast";

function PolicyHolder(props) {
  // const patientId = window.location.href.split("/").pop();
  const rowData = JSON.parse(localStorage.getItem("RowData"));

  const initialState = {
    active: 1,
    id: 0,
    firstName: "",
    lastName: "",
    middleName: "",
    clinicId: 0,
    dob: "",
    patientId: "",
    phone: "",
    relation: "",
    sex: "",
    addressLine1: "",
    addressLine2: "",
    city: "",
    state: "",
    zip: "",
    country: "US",
  };

  const [values, setValues] = useState(initialState);
  const [input, setInput] = useState({ ...initialState });
  const [selectedOption, setSelectedOption] = useState(false);

  const relation = [
    { name: "Father", value: 1 },
    { name: "Mother", value: 2 },
    { name: "Spouse", value: 3 },
    { name: "Brother", value: 4 },
    { name: "Sister", value: 5 },
    { name: "Other", value: 6 },
    { name: "Self", value: 7 },
    { name: "Guarantor", value: 8 },
    { name: "Child", value: 9 },
    { name: "Employee", value: 10 },
    { name: "Unknown", value: 11 },
    { name: "Organdonor", value: 12 },
    { name: "Cadaverdonor", value: 13 },
    { name: "Lifepartner", value: 14 },
  ];

  const gender = [
    { name: "Male", value: 1 },
    { name: "Female", value: 2 },
  ];

  const handleSubmit = (e) => {
    e.preventDefault();
    if (selectedOption === false) {
      props.policyHolderSave({
        ...input,
        patientId: JSON.parse(localStorage.getItem("patientInfo"))?.id,
        clinicId: rowData.clinicId,
      });
    } else {
      props.policyHolderSave({
        ...values,
        patientId: JSON.parse(localStorage.getItem("patientInfo"))?.id,
        clinicId: rowData.clinicId,
      });
    }
    clearForm();
  };

  const clearForm = () => {
    setValues(initialState);
    setInput(initialState);
    setSelectedOption(false);
  };

  useEffect(() => {
    if (props.demographicsData.policyHolderSave) {
      let response = props.demographicsData.policyHolderSave.response;
      if (response.responseCode === 0) {
        toast.success("Policy Holder Saved Successfully");
        clearForm();
        props.handleClose();
        // let id = window.location.href.split("/").pop();
        props.insuranceList(
          JSON.parse(localStorage.getItem("patientInfo"))?.id
        );
        props.demographicsData.policyHolderSave = null;
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.demographicsData.policyHolderSave]);

  // Load patientInfo from localStorage on mount and setup storage event listener
  useEffect(() => {
    const updatePatientInfo = () => {
      const newPatientInfo = JSON.parse(localStorage.getItem("patientInfo"));
      setValues((prevValues) => ({
        ...prevValues,
        ...newPatientInfo,
      }));
    };

    // Initial load
    updatePatientInfo();

    // Listen for storage changes
    window.addEventListener("storage", updatePatientInfo);

    // Cleanup event listener on unmount
    return () => {
      window.removeEventListener("storage", updatePatientInfo);
    };
  }, []);

  // Clear state on component mount
  useEffect(() => {
    clearForm();
  }, []);

  const HandleDropDown = (e) => {
    const newPatientInfo = JSON.parse(localStorage.getItem("patientInfo"));
    if (e.target.value === 7) {
      setSelectedOption(true);
      setValues({
        active: newPatientInfo.active,
        id: 0,
        firstName: newPatientInfo.firstName,
        lastName: newPatientInfo.lastName,
        middleName: newPatientInfo.middleName,
        clinicId: newPatientInfo.clinicId,
        dob: newPatientInfo.dob,
        patientId: newPatientInfo.patientId,
        phone: newPatientInfo.phone,
        relation: e.target.value,
        sex: newPatientInfo.sex,
        addressLine1: newPatientInfo.addressLine1,
        addressLine2: newPatientInfo.addressLine2,
        city: newPatientInfo.city,
        state: newPatientInfo.state,
        zip: newPatientInfo.zip,
        country: "US",
      });
    } else {
      setSelectedOption(false);
      setValues({
        ...initialState,
        relation: e.target.value,
      });
      setInput((prevInput) => ({
        ...prevInput,
        relation: e.target.value,
      }));
    }
  };

  return (
    <Dialog
      fullWidth={true}
      maxWidth={"sm"}
      open={props.open}
      onClose={() => {
        clearForm();
        props.handleClose();
      }}
      style={{ padding: "3rem" }}
    >
      {/* Policy Holder */}
      <DialogTitle>Policy Holder</DialogTitle>
      <DialogContent>
        <form onSubmit={handleSubmit}>
          <Grid container spacing={2}>
            <Grid item xs={4}>
              <Input
                required
                type="text"
                label="First name"
                value={input.firstName || values.firstName}
                onChange={(e) =>
                  selectedOption
                    ? setValues({ ...values, firstName: e.target.value })
                    : setInput({ ...input, firstName: e.target.value })
                }
                name="firstName"
              />
            </Grid>
            <Grid item xs={4}>
              <Input
                type="text"
                label="Middle Name"
                value={input.middleName || values.middleName}
                onChange={(e) =>
                  selectedOption
                    ? setValues({ ...values, middleName: e.target.value })
                    : setInput({ ...input, middleName: e.target.value })
                }
                name="middleName"
              />
            </Grid>
            <Grid item xs={4}>
              <Input
                required
                type="text"
                label="Last name"
                value={input.lastName || values.lastName}
                onChange={(e) =>
                  selectedOption
                    ? setValues({ ...values, lastName: e.target.value })
                    : setInput({ ...input, lastName: e.target.value })
                }
                name="lastName"
              />
            </Grid>
            <Grid item xs={4}>
              <DobPicker
                required
                label="DOB"
                maxDate={new Date()}
                value={input.dob || values.dob}
                onChange={(e) =>
                  selectedOption
                    ? setValues({ ...values, dob: e.target.value })
                    : setInput({ ...input, dob: e.target.value })
                }
                inputVariant={"standard"}
              />
            </Grid>
            <Grid item xs={4}>
              <Dropdown
                required
                options={gender}
                label="Sex"
                value={input.sex || values.sex}
                onChange={(e) =>
                  selectedOption
                    ? setValues({ ...values, sex: e.target.value })
                    : setInput({ ...input, sex: e.target.value })
                }
              />{" "}
            </Grid>
            <Grid item xs={4}>
              <Dropdown
                required
                tabIndex={7}
                id="relation"
                options={relation}
                label="Relation"
                value={input.relation || values.relation}
                onChange={(e) => HandleDropDown(e)}
              />{" "}
            </Grid>
            <Grid item xs={4}>
              <Input
                required
                type="text"
                label="Address line 1"
                value={input.addressLine1 || values.addressLine1}
                onChange={(e) =>
                  selectedOption
                    ? setValues({ ...values, addressLine1: e.target.value })
                    : setInput({ ...input, addressLine1: e.target.value })
                }
                name="addressLine1"
              />{" "}
            </Grid>
            <Grid item xs={4}>
              <Input
                tabIndex={14}
                id="address2"
                type="text"
                label="Address line 2"
                value={input.addressLine2 || values.addressLine2}
                onChange={(e) =>
                  selectedOption
                    ? setValues({ ...values, addressLine2: e.target.value })
                    : setInput({ ...input, addressLine2: e.target.value })
                }
                name="addressLine2"
              />{" "}
            </Grid>
            <Grid item xs={4}>
              <PhoneFormat
                required
                type="text"
                label="Phone"
                value={input.phone || values.phone}
                onChange={(e) =>
                  selectedOption
                    ? setValues({ ...values, phone: e.target.value })
                    : setInput({ ...input, phone: e.target.value })
                }
                name="phone"
              />
            </Grid>
            <Grid item xs={4}>
              <Input
                type="text"
                label="Zip"
                value={input.zip || values.zip}
                onChange={(e) =>
                  selectedOption
                    ? setValues({ ...values, zip: e.target.value })
                    : setInput({ ...input, zip: e.target.value })
                }
                name="zip"
              />{" "}
            </Grid>
            <Grid item xs={3}>
              <Input
                type="text"
                label="City"
                value={input.city || values.city}
                onChange={(e) =>
                  selectedOption
                    ? setValues({ ...values, city: e.target.value })
                    : setInput({ ...input, city: e.target.value })
                }
                name="city"
              />{" "}
            </Grid>
            <Grid item xs={3}>
              <Dropdown
                options={states}
                label="State"
                value={input.state || values.state}
                onChange={(e) =>
                  selectedOption
                    ? setValues({ ...values, state: e.target.value })
                    : setInput({ ...input, state: e.target.value })
                }
              />{" "}
            </Grid>
            <Grid item xs={3}>
              <Dropdown
                options={[
                  { name: "USA", value: "US" },
                  { name: "Mexico", value: "Mexico" },
                ]}
                label="Country"
                value={values.country}
                onChange={(e) =>
                  selectedOption
                    ? setValues({ ...values, country: e.target.value })
                    : setInput({ ...input, country: e.target.value })
                }
              />
            </Grid>
            {/* Save and Cancel buttons */}
            <Grid item xs={12} container justify="flex-end">
              <div
                style={{ display: "flex", padding: "1rem 1rem 0", gap: "1rem" }}
              >
                <Button
                  onClick={() => {
                    clearForm();
                    props.handleClose();
                  }}
                  color="primary"
                >
                  Cancel
                </Button>
                <Button variant="contained" color="primary" type="submit">
                  Save
                </Button>
              </div>
            </Grid>
          </Grid>
        </form>
      </DialogContent>
    </Dialog>
  );
}

const mapStateToProps = (state) => ({
  demographicsData: state.demographics,
});

const mapDispatchToProps = (dispatch) => ({
  policyHolderSave: (values) => dispatch(policyHolderSave(values)),
  insuranceList: (values) => dispatch(insuranceList(values)),
});

PolicyHolder.propTypes = {
  policyHolderSave: PropTypes.func,
  insuranceList: PropTypes.func,
  open: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  demographicsData: PropTypes.object.isRequired,
};

export default connect(mapStateToProps, mapDispatchToProps)(PolicyHolder);
